import React, { useState } from 'react';
import { useTransition, animated } from '@react-spring/web';
import styles from './styles.module.css';
import york1 from './york1.png';
import york2 from './york2.jpg';
import york3 from './york3.jpg';
import york4 from './york4.png';

const slides = [york1, york3, york2, york4];

export default function Education2() {
  const [index, set] = useState(0);
  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 3000 },
    onRest: (_a, _b, item) => {
      if (index === item) {
        set((state) => (state + 1) % slides.length);
      }
    },
    exitBeforeEnter: true,
  });

  return (
    <div className=" flex fill center">
      {transitions((style, i) => (
        <animated.div
          className={styles.bg10}
          style={{
            ...style,
            backgroundImage: `url(${slides[i]})`, // Corrected line
          }}
        />
      ))}
    </div>
  );
}
