import React, { useEffect, useRef } from 'react';
import './styles.css'
import team from './team1-removebg-preview.png'
import rocket from './rocket-removebg-preview.png'
import screens from './screens-removebg-preview.png'
import bounce from './bounce-removebg-preview.png'


function MyComponent() {
  // Generate a random number between 1 and 6
  const randomValue = Math.floor(Math.random() * 6) + 1;

  return (
    <div>
      <p>Random Number between 1 and 6: {randomValue}</p>
    </div>
  );
}

const getRandomColor  = () => {
  const colors = ['blue', 'yellow', 'white', 'green', 'purple', 'red'];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};


const App = () => {
  const containerRef = useRef(null);

  

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start your rotation animation here
          entry.target.classList.add('start-animation');
          
           // Directly add the start-color-animation class to wall elements
        document.querySelectorAll('.wall1, .wall2, .wall3, .wall4, .wall5, .wall6').forEach((wall) => {
        wall.classList.add('start-color-animation');
      });
      
          // Set random border color
          entry.target.style.borderColor = getRandomColor();
      
          // Delay the appearance of all img tags for 1.5 seconds if width > 640px
          const imgTags = entry.target.querySelectorAll('img');
          const pTags = entry.target.querySelectorAll('p');

          imgTags.forEach((img, index) => {
              // Add the fade-in class dynamically with a delay based on the index
              img.classList.add('fade-in');
              if (window.innerWidth > 640) {
                img.style.animationDelay = `${2}s`; // Adjust the delay as needed
              } else {
                  img.style.animationDelay = `${0}s`; // No delay if width <= 640px
              }
          });

          pTags.forEach((p, index) => {
              // Add the fade-in class dynamically with a delay based on the index
              p.classList.add('fade-in-p');
              if (window.innerWidth > 640) {
                  p.style.animationDelay = `${2}s`; // Adjust the delay as needed
              } else {
                  p.style.animationDelay = `${0}s`; // No delay if width <= 640px
              }
          });
          
        } 
      });
    };
    
    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
    const observer2 = new IntersectionObserver(handleIntersection, { threshold: 0.1 });
    // Assuming your target element is with the class 'body-500-wrapper'
    const targetElement = document.querySelector('.body-500-wrapper');
    observer.observe(targetElement);

    const targetElement2 = document.querySelector('.cont-500-wrapper');
    observer2.observe(targetElement2);
  
    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);
  

  

  return (
<div className='scale-500'>
  <div >
<div ref={containerRef} className = "body-500-wrapper">
    <div className="body-500-1">
    <img src = {rocket} className="" style={{ opacity: 0 }} />
      <div className="">
        <div className="flex-500">
          <div className="cube">
            <div className=" wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
          <div className=" wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
          <div className=" wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall2 right"></div>
            <div className="wall1 top"></div>
            <div className="wall6 bottom"></div>
          </div>
          <div className="cube">
          <div className=" wall1 front"></div>
            <div className="wall4 back"></div>
            <div className="wall5 left"></div>
            <div className="wall2 right"></div>
            <div className="wall3 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
          <div className=" wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
          <div className=" wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className=" wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className=" wall1 front"></div>
            <div className="wall4 back"></div>
            <div className="wall5 left"></div>
            <div className="wall2 right"></div>
            <div className="wall3 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
         
          <div className="cube">
            <div className=" wall1 front"></div>
            <div className="wall4 back"></div>
            <div className="wall5 left"></div>
            <div className="wall2 right"></div>
            <div className="wall3 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className=" wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className=" wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className=" wall1 front"></div>
            <div className="wall4 back"></div>
            <div className="wall5 left"></div>
            <div className="wall2 right"></div>
            <div className="wall3 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
            <div className=" wall2 front"></div>
            <div className="wall6 wall2 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className=" wall2 front"></div>
            <div className="wall3 back"></div>
            <div className="wall3 left"></div>
            <div className="wall3 right"></div>
            <div className="wall3 top"></div>
            <div className="wall3 bottom"></div>
          </div>
          <div className="cube">
            <div className=" wall2 front"></div>
            <div className="wall6 wall2 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall2 wall2 front"></div>
            <div className="wall6 wall2 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall2 bottom"></div>
          </div>
        </div>
        
      </div>
      
    </div>
    
    
    <div className="body-500-2">
    <img src = {bounce} className="" style={{ opacity: 0 }} />
      <div className="">
      
        <div className="flex-500">
          
          <div className="cube">
            <div className="wall2 wall2 front"></div>
            <div className="wall4 back"></div>
            <div className="wall5 left"></div>
            <div className="wall2 right"></div>
            <div className="wall3 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall2 wall2 front"></div>
            <div className="wall4 back"></div>
            <div className="wall5 left"></div>
            <div className="wall2 right"></div>
            <div className="wall3 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall2 wall2 front"></div>
            <div className="wall4 back"></div>
            <div className="wall5 left"></div>
            <div className="wall2 right"></div>
            <div className="wall3 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall4 back"></div>
            <div className="wall5 left"></div>
            <div className="wall2 right"></div>
            <div className="wall3 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall3 left"></div>
            <div className="wall4 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall3 left"></div>
            <div className="wall4 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall3 left"></div>
            <div className="wall4 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall3 left"></div>
            <div className="wall4 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="body-500-3">
      <img src = {screens} className="" style={{ opacity: 0 }} />
      <div className="">
        <div className="flex-500">
          <div className="cube">
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall3 left"></div>
            <div className="wall4 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall3 left"></div>
            <div className="wall4 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall3 left"></div>
            <div className="wall4 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
      </div>
    </div>
    
    
    <div className="body-500-4">
    <img src = {team} className="" style={{ opacity: 0 }} />
      <div className="">
        <div className="flex-500">
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
        </div>
        <div className="flex-500">
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall1 wall1 front"></div>
            <div className="wall6 wall1 front"></div>
            <div className="wall5 back"></div>
            <div className="wall4 left"></div>
            <div className="wall3 right"></div>
            <div className="wall2 top"></div>
            <div className="wall1 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall1 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall1 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
          <div className="cube">
            <div className="wall4 wall2 front"></div>
            <div className="wall2 back"></div>
            <div className="wall5 left"></div>
            <div className="wall2 right"></div>
            <div className="wall4 top"></div>
            <div className="wall2 bottom"></div>
          </div>
        </div>
      </div>
      
    </div>
   
    </div>
    <div className='cont-500-wrapper '>
      <div className='cont-500-1 ' >
        <p className='p1'>Fast</p>
        <p className='p2'>High-performance code for optimal experiences.</p>
      </div>

      <div className='cont-500-2 '>
        <p className='p1'>Dynamic</p>
        <p className='p2'>Interactive web solutions with real-time features.</p>
      </div>

      <div className='cont-500-3 ' >
      <p className='p1'>Responsive</p>
        <p className='p2'>Layouts that adapt seamlessly to any device.</p>
      </div>

      <div className='cont-500-4 ' >
        <p className='p1'>Collaboration</p>
        <p className='p2'>Foster effective communication for project success.</p>
      </div>
    </div>
  </div>
  </div>
);
};

export default App;
