import React, { useState } from 'react';
import { useEffect  } from 'react';
import "./Styles/contactPage-10001.css"
import { FaLinkedin, FaGithub, FaInstagram, FaFacebook, FaDiscord, FaSnapchat } from 'react-icons/fa';
import { FaChevronRight } from "react-icons/fa";
import Message from "./components/animationpics/message.jsx";
import Connect from "./components/animationpics/connect.jsx";




export const Contact = () => {
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTagClick = (template) => {
    setMessage(template);
  };

  const handleClearClick = () => {
    setMessage('');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData(e.target);
    const name = formData.get('name');
    const email = formData.get('email');
    const message = formData.get('message');
    
    try {
      const currentDate = new Date().toISOString(); // Get the current date in ISO format
      const response = await fetch('https://portfolio-messages-manager.vercel.app/submit-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, email, message, date: currentDate })
      });
      
      if (!response.ok) {
        throw new Error('Failed to submit message');
      }
      
      // Handle successful submission
      setShowSuccess(true);
      setShowError(false);
    } catch (error) {
      // Handle errors
      setShowSuccess(false);
      setShowError(true);
      console.error('Error submitting message:', error.message);
    }
  };


  const handleClosePopup = () => {
    setShowSuccess(false);
    setShowError(false);
    setName('');
    setEmail('');
    setMessage('');
  };

  const handleClosePopup2 = () => {
    setShowSuccess(false);
    setShowError(false);

  };


  return (
    <div className='conact-me' style={{position:"absolute"}}>
      <Connect/>
    <div className="contact-page-10001">
        {showSuccess && (
          <div className="popup-10001 success-10001" >
            <p>Message submitted successfully!</p>
            <button onClick={handleClosePopup}>Okay</button>
          </div>
        )}
        {showError && (
          <div className="popup-10001 error-10001"  >
            <p style={{textAlign:'center'}}>Error submitting message. Please try again or <br/> <a href="https://www.linkedin.com/in/andrewrizk3030/">reach out to me on LinkedIn</a>.</p>
            <button  onClick={handleClosePopup2}>Okay</button>
          </div>
        )}
      <div className="get-social-container-10001">
        <p><FaChevronRight style={{paddingTop:"10px",fontSize:"30px"}}/>Lets get Soctial</p>
        <div className="social-row-10001">
          <SocialButton icon={<FaLinkedin />} appName="LinkedIn" myName="Andro Rizk" onClick={() => window.open("https://www.linkedin.com/in/andrewrizk3030/", "_blank")} />
          <SocialButton icon={<FaGithub />} appName="GitHub" myName="Andrewid" onClick={() => window.open("https://github.com/AndrewidRizk", "_blank") }/>
        </div>
        <div className="social-row-10001">
          <SocialButton icon={<FaInstagram />} appName="Instagram" myName="androw_m" onClick={() => window.open("https://www.instagram.com/androw_m/", "_blank")} />
          <SocialButton icon={<FaFacebook />} appName="Facebook" myName="Andrew Rizk" onClick={() => window.open("https://www.facebook.com/androw.maged.319", "_blank")} />
        </div>
        <div className="social-row-10001">
          <SocialButton icon={<FaDiscord />} appName="Discord" myName="Andrew Rizk" onClick={() => window.open("https://discord.com/invite/gfuuJFwQ", "_blank")} />
          <SocialButton icon={<FaSnapchat />} appName="Snapchat" myName="Andrew Rizk" onClick={() => window.open("https://snapchat.com/t/XKX8O78N", "_blank")} />
        </div>
      </div>
      <Message/>
      <div className="send-message-container-10001">
      
        <p><FaChevronRight style={{paddingTop:"10px",fontSize:"30px"}}/>Send me a Message</p>
        <div className="tags-container-10001">
        <div className="tag-10001" onClick={() => handleTagClick('Hey there! Just wanted to drop by and give you a virtual round of applause for your fantastic website! 👏 It\'s sleek, it\'s stylish, and it\'s got that \'wow\' factor. Keep up the amazing work!')}>Compliment on the website 👏</div>
          <div className="tag-10001" onClick={() => handleTagClick('Hi! Your work really caught my eye, and I\'d love to connect and learn more about what you do. Let\'s grab a virtual coffee sometime and chat about all things creative! ☕️')}>Would love to connect 🤝</div>
          <div className="tag-10001" onClick={() => handleTagClick('Hello fellow TV enthusiast! 📺 I couldn\'t help but notice your passion for _______________. It\'s my absolute favorite too! Let\'s geek out together and swap theories over popcorn sometime!')}>We like the same shows 📺</div>
          <div className="tag-10001" onClick={() => handleTagClick('Hey sporty friend! 🏐🏓 I see you\'re into volleyball and ping pong, and I\'m always up for a friendly match! Whether it\'s spiking the ball or rallying at the table, let\'s team up and have a smashing time together! 🚀')}>We should hangout and play 🏓</div>
          <div className="tag-10001" onClick={() => handleTagClick('Haikyu fan alert! 🏐 Your profile caught my attention because of that awesome reference to the show. It\'s not just a series; it\'s a way of life! Let\'s dive into the world of volleyball together, set up our strategies, and spike our way through friendship! 🙌')}>Haikyu Rules 🏐</div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input-container-10001">
            <input type="text" name="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)}/>
            <input type="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
          </div>
          <div className="message-container-10001">
            <textarea name="message" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
          </div>
          <div className="button-container-10001">
            <button type="button" onClick={handleClearClick}>Clear</button>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>

    {windowWidth < 1000 ?
        <div style={{color:"transparent",  marginTop:"200px"}}><p>a</p>  </div>
        :
        <div style={{color:"transparent"}}><p>a</p>  </div>
        }


    </div>
  );
};

const SocialButton = ({ icon, appName, myName, onClick }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div className="social-button-10001" onClick={onClick} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <div className={`button-icon ${hovered ? 'hovered' : ''}`}>{icon}</div>
      <div className="button-text">
        <span className={`${hovered ? 'hidden' : ''}`}>{appName}</span>
        <strong>{myName}</strong>
      </div>
    </div>
  );
};