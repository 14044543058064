import React from 'react';
import './YourComponent.css'; // Import your CSS file
import { useEffect, useState } from 'react';

const YourComponent = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Function to check if the screen size falls into the specified ranges
    const checkScreenSize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth <= 973 ? 'small' : (screenWidth <= 1412 ? 'medium' : 'large'));
    };

    // Initial check on mount
    checkScreenSize();

    // Event listener for changes in screen size
    window.addEventListener('resize', checkScreenSize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount

return (
    <div>
                          <div className="">
                <div className="column16 ">
              {/* GitHub stuff */}
             
                
             
                <a className='github-stuff16 ' href="https://github.com/AndrewidRizk">
                  <img src="https://github-readme-streak-stats.herokuapp.com/?user=AndrewidRizk&hide_border=true&card_width=338&theme=vision_friendly_dark"  
                  alt="GitHub Profile Summary Card" 
                  width="300" 
                   height="170" 
                  />
                </a>
                <a className='github-stuff16 ' href="https://github.com/AndrewidRizk">
                <img src="https://github-readme-stats-git-masterrstaa-rickstaa.vercel.app/api/top-langs/?username=AndrewidRizk&theme=transparent&hide_border=true&layout=compact"   
                alt="GitHub Profile Summary Card" 
                width="300" 
                height="170" 
                />
                </a>
                <a className='github-stuff16 ' href="https://github.com/AndrewidRizk">
                <img src="http://github-profile-summary-cards.vercel.app/api/cards/most-commit-language?username=AndrewidRizk&theme=vision_friendly_dark" 
                    alt="GitHub Profile Summary Card" 
                    width="300" 
                    height="170" 
                />
                </a>

                <a className='github-stuff16 ' href="https://github.com/AndrewidRizk">
                <img src="http://github-profile-summary-cards.vercel.app/api/cards/productive-time?username=AndrewidRizk&theme=vision_friendly_dark" 
                    alt="GitHub Profile Summary Card" 
                    width="300" 
                    height="170" 
                />
                </a>
          
      
              
            </div>
              </div>
    </div>
  );
};



export default YourComponent;
