import React from 'react';
import styled, { keyframes } from 'styled-components';

const wave = keyframes`
  0%, 100% { transform: rotate(0deg); }
  20% { transform: rotate(-20deg); }
  40% { transform: rotate(20deg); }
  60% { transform: rotate(-20deg); }
  80% { transform: rotate(20deg); }
`;

const WavingSpan = styled.span`
  display: inline-block;
  animation: ${wave} 2s infinite;
  transform-origin: 70% 70%; /* Adjust origin to make it look natural */
`;

const WavingEmoji = ({ children }) => {
  return <WavingSpan>{children}</WavingSpan>;
};

export default WavingEmoji;
