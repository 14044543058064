import React from 'react';
import styled, { keyframes } from 'styled-components';
import astronautFloating from './Volleyball.png'; // Adjust the path to your image

const floatHorizontal = keyframes`
  0%, 100% {
    transform: translateX(0) rotate(-15deg); /* Tilt to the right by 15 degrees */
  }
  50% {
    transform: translateX(-20%) rotate(-15deg); /* Move horizontally and keep the tilt */
  }
`;

const FloatingAstronaut = styled.img`
  position: absolute;
  top: -15%; /* Adjust top position as needed */
  left: 2%; /* Adjust left position as needed */
  width: 100px; /* Default size */
  height: auto;
  animation: ${floatHorizontal} 3s ease-in-out infinite;

  @media (max-width: 1300px) {
    top: -12%; /* Adjust top position as needed */
  }
  @media (max-width: 919px) {
    width: 90px; /* Smaller size */
    top: -8%; /* Adjust top position for smaller screens */
    left:5%;
  }
  @media (max-width: 768px) {
    width: 80px; /* Smaller size */
  }
  @media (max-width: 479px) {
    width: 70px; /* Smaller size */
    left:10%;
  }

  @media (max-width: 362px) {
    width: 70px; /* Smaller size */
    top: -10%; /* Adjust top position for smaller screens */
    left:15%;
  }

  @media (max-width: 298px) {
    width: 70px; /* Smaller size */
    top: -6%; /* Adjust top position for smaller screens */
    left:15%;
  }




  @media (min-width: 1401px) {
    width: 120px; /* Bigger size */
    left: 0%; /* Adjust left position as needed */
  }
`;

const AstronautFloatAnimation = () => {
  return <FloatingAstronaut src={astronautFloating} alt="Floating Astronaut" />;
};

export default AstronautFloatAnimation;
