import React from 'react';
import styled, { keyframes } from 'styled-components';
import astronautWithComputer from './SendMessage.png'; // Adjust the path to your image

const floatVertical = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust the vertical movement as needed */
  }
`;

const FloatingAstronaut = styled.img`

position: absolute; /* Stay within the parent container */
  top:10%;

  width: 100px; /* Default size */
  height: auto;
  animation: ${floatVertical} 3s ease-in-out infinite;
  z-index: 9999; /* Ensure it is on top of everything */
  

  @media (max-width: 1448px) {
    width: 100px; /* Bigger size */
    right: 1%; /* Adjust position for larger screens */
  }
  @media (max-width: 1300px) {
    width: 80px; /* Bigger size */
    right: 0%; /* Adjust position for larger screens */
   
  }
  @media (max-width: 1085px) {
    width: 100px; /* Smaller size */
  }
  @media (max-width: 893px) {
    width: 80px; /* Smaller size */
  }
  @media (max-width: 769px) {
    width: 85px; /* Smaller size */
    top:4%;
  }
  @media (max-width: 688px) {
   top:50%
  }
  @media (max-width: 600px) {
   top:48%
  }
  @media (max-width: 412px) {
   top:42%
  }
  @media (max-width: 275px) {
   top:46%
  }
  @media (max-width: 298px) {
    width: 70px; /* Smaller size */
    bottom: 8%; /* Adjust position for smaller screens */
  }
  @media (min-width: 1449px) {
    width: 120px; /* Bigger size */
    right: 2%; /* Adjust position for larger screens */
  }
`;

const AstronautWithComputer = () => {
  return <FloatingAstronaut src={astronautWithComputer} alt="Astronaut with Computer" />;
};

export default AstronautWithComputer;
