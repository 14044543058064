import React, { useRef, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import astronautFloating from './Skills.png'; // Adjust the path to your image

const floatHorizontal = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw)rotate(35deg);
  }
`;

const FloatingAstronaut = styled.img`
  position: absolute;
  top: -15%; /* Adjust top position as needed */
  left: 0; /* Start off-screen */
  width: 100px; /* Default size */
  height: auto;
  ${({ isVisible }) => isVisible && css`
    animation: ${floatHorizontal} 3.5s ease-in-out forwards;
  `}

  @media (max-width: 1300px) {
  }
  @media (max-width: 919px) {
    width: 90px; /* Smaller size */
  }
  @media (max-width: 768px) {
    width: 80px; /* Smaller size */
  }
  @media (max-width: 479px) {
    width: 70px; /* Smaller size */
  }
  @media (max-width: 362px) {
    width: 70px; /* Smaller size */
  }
  @media (max-width: 298px) {
    width: 70px; /* Smaller size */
  }
  @media (min-width: 1401px) {
    width: 120px; /* Bigger size */
  }
`;

const AstronautFloatAnimation = () => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after the animation is triggered
        }
      },
      {
        threshold: 0.5, // Adjust the threshold as needed
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return <FloatingAstronaut ref={ref} src={astronautFloating} alt="Floating Astronaut" isVisible={isVisible} />;
};

export default AstronautFloatAnimation;
