// AnotherComponent.jsx

import React from 'react';

import Cubes from "../Mainskills/cubes"
import Github from './separators';
import Tools from  './tools.jsx'

const Finallskills = () => {
  return (
    <div>
      <Cubes/>
      <Tools/>
      <Github />
        
      
    </div>
  );
};

export default Finallskills;
