import React from 'react';
import styled, { keyframes } from 'styled-components';
import astronaut from './Hello-there.png';

const moveUpDown = keyframes`
  0%, 100% {
    transform: translateY(0) rotate(5deg); /* Tilt to the right by 15 degrees */
  }
  50% {
    transform: translateY(-20%) rotate(5deg); /* Keep the tilt while moving */
  }
`;

const Astronaut = styled.img`
  position: absolute;
  width: 100px; /* Default size */
  height: auto;
  top: 30%;
  right: 15%;
  animation: ${moveUpDown} 3s ease-in-out infinite;


  @media (max-width: 768px) {
    width: 100px; /* Smaller size */
    top: 30%;
    right: 10%;
  }

  @media (min-width: 1401px) {
    width: 120px; /* Bigger size */
    top: 30%;
    right: 25%;
  }
  @media (max-width: 432px) {
    width: 100px; /* Smaller size */
    top: 28%;
    right: 10%;
  }

  @media (max-width: 313px) {
    width: 100px; /* Smaller size */
    top: 25%;
    right: 10%;
  }

  @media (max-width: 259px) {
    width: 100px; /* Smaller size */
    top: 20%;
    right: 10%;
  }
`;

const AstronautAnimation = () => {
  return <Astronaut src={astronaut} alt="Astronaut" />;
};

export default AstronautAnimation;
