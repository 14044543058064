import React, { useRef, useMemo } from "react";
import {useState, useEffect } from 'react';
import "./Styles/About.css";
import { FaRegGrinAlt } from "react-icons/fa";
import Masonry from './components/Introduction.tsx';
import { FaArrowUp } from "react-icons/fa6";
import {IoEllipsisVerticalOutline} from "react-icons/io5";
import me from "./components/photos/WhatsApp.jpeg"
import { MdInsertPhoto } from "react-icons/md";
import Aboutme from './components/AboutMe/Aboutme.js';
import Smile from './components/Smile Grid/Smile.tsx'
import Shooting from '../../Shooting.js';
import Education2 from './components/Education/edu.tsx'
import './components/Education/index.css'
import BoxContainer from './components/slide show/slides.jsx'
import BoxContainer2 from './components/slide show/slides2.jsx'
import { FaAngleRight } from "react-icons/fa";
import { LiaVolleyballBallSolid } from "react-icons/lia";
import { GiPingPongBat } from "react-icons/gi";
import Books from './components/bookdropdown/bookdropdown.js' 
import AstronautFloatAnimation  from './components/animationpics/volleyball.jsx' 
import WavingEmojy  from './components/animationpics/WavingEmojy.jsx' 

import Pic1 from './photos/picture_1.png'
import Pic2 from './photos/picture_2.jpg'
import Pic3 from './photos/picture_3.png'
import Pic4 from './photos/picture_4.png'
import Pic5 from './photos/picture_5.png'
import Pic6 from './photos/picture_6.png'
import Pic7 from './photos/picture_7.jpg'
import Pic8 from './photos/picture_8.png'
import Pic9 from './photos/picture_9.jpg'
import Pic10 from './photos/picture_10.png'

export const About = () => {
  const Up = useRef(null);
  const Introduction1 = useRef(null);
  const photos = useRef(null);


  const scrollToSection = (elementRef) => {
    if (elementRef && elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  

  const [isHoveringIntro, setIsHoveringIntro] = useState(false);
  const [isHoveringEdu, setIsHoveringEdu] = useState(false);
  const [isHoveringExp, setIsHoveringExp] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFadingIntro, setIsFadingIntro] = useState(false);
  const [isFadingEdu, setIsFadingEdu] = useState(false);
  const [isFadingExp, setIsFadingExp] = useState(false);

  const introTimeout = useRef(null);
  const eduTimeout = useRef(null);
  const expTimeout = useRef(null);

  const isMobile = windowWidth <= 768;

  const handleIntroMouseOver = () => {
    setIsHoveringIntro(true);
    setIsFadingIntro(false);
    if (windowWidth <= 768) {
      introTimeout.current = setTimeout(() => {
        setIsFadingIntro(true);
        setTimeout(() => {
          setIsHoveringIntro(false);
        }, 500); // Duration of fade-out animation
      }, 1000);
    }
  };

  const handleIntroMouseOut = () => {
    if (introTimeout.current) {
      clearTimeout(introTimeout.current);
    }
    setIsHoveringIntro(false);
    setIsFadingIntro(false);  
  };

  const handleEduMouseOver = () => {
    setIsHoveringEdu(true);
    setIsFadingEdu(false);
    if (windowWidth <= 768) {
      eduTimeout.current = setTimeout(() => {
        setIsFadingEdu(true);
        setTimeout(() => {
          setIsHoveringEdu(false);
        }, 500); // Duration of fade-out animation
      }, 1000);
    }
  };

  const handleEduMouseOut = () => {
    if (eduTimeout.current) {
      clearTimeout(eduTimeout.current);
    }
    setIsHoveringEdu(false);
    setIsFadingEdu(false);
  };

  const handleExpMouseOver = () => {
    setIsHoveringExp(true);
  };

  const handleExpMouseOut = () => {
    setIsHoveringExp(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    const booksSpan = document.getElementById("books-span");
    const rect = booksSpan.getBoundingClientRect();
    setDropdownPosition({
      left: rect.left -10
    });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <div className="About" >
      <div className="Container">
        <ul>

        <li onClick={() => scrollToSection(Up)} className="link nav-item icon">
        <FaArrowUp />
          </li>

          <li></li>
          <li></li>
          
              <li onClick={() => scrollToSection(photos)} className="link nav-item icon"
                onMouseOver={handleIntroMouseOver}
                onMouseOut={handleIntroMouseOut}>
                  <div>
            <MdInsertPhoto />
            {isMobile ? (
              isHoveringIntro &&<span className={`tooltip2 ${isFadingIntro ? 'hidden' : ''}`}>
                {"                               "}Photos
              </span>
            ) : (
              isHoveringIntro &&<span className="tooltip">
                {"                               "}Photos
              </span>
            )}
                </div>
              </li>

          <li></li>
          <li></li>
              <li onClick={() => scrollToSection(Introduction1)} className="link nav-item icon"
                onMouseOver={handleEduMouseOver}
                onMouseOut={handleEduMouseOut}
              >
                
                <div>
                  <GiPingPongBat/>
                  {isMobile ? (
              isHoveringEdu  && <span className={`tooltip2 ${isFadingEdu ? 'hidden' : ''}`}>
                {"                               "}Interests
              </span>
            ) : (
              isHoveringEdu  && <span className="tooltip">
                {"                               "}Interests
              </span>
            )}
                </div>

              </li>

        </ul>
        
      </div>



      <div ref={Up} className="Introduction">
      
      <h3 style={{ width: '50%', margin: '0 auto', textAlign: 'center', fontSize: "30px"}}>Hello There <WavingEmojy>👋</WavingEmojy></h3>
      
      
      {windowWidth < 1000 ?
        <div class="textbox-container  fade-9002">
          
            <div class="textbox1 ">
        
              <p >
              I'm Andrew Rizk, an honors undergraduate Computer Science at York University. While my roots lie in coding and algorithms, my life has diverse passions and interests. I'm fueled by an insatiable curiosity for learning, exploring various genres of literature from captivating novels to enlightening personal development 
              <span 
                id="books-span"
                className="books-container-9002 ancer-2004" 
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}
              > books. </span> 
             {isHovered && (
            <div 
              className="dropdown-content-9002" 
              style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
            >
              <ul>
                <li>Think and grow rich - Napoleon Hill</li>
                <li>Zero to One - Peter Thiel</li>
                <li>7 habits of highly effective people - R. Covey</li>
                <li>Rish Dad Poor Dad - Robert Kiyosaki</li>
                <li>Da Vinci's Code - Dan Brown</li>
                <li>Angels & Demons - Dan Brown</li>
                <li>The Cairo Trilogy - Nagib Mahfuz</li>
                <li>It Ends/Starts With Us - Collen Hover</li>
                {/* Add more books as needed */}
              </ul>
            </div>)}
               I also enjoy <a className="ancer-2004" onClick={() => scrollToSection(Introduction1)}> gaming, shows and sports.</a> However, what truly sets me apart is my unwavering determination and commitment to my long term goals, with <a className="ancer-2004" href="./projects"> coding new projects</a> always been my main interest.
              </p>
              
              <div ref={photos} class="">
                <Slideshow/>
              </div>
              <br/>
              <QuoteComponent/>
              </div>


              
              
        </div>
        :

                        <div class="textbox-container content-container2 fade-9002">
                        <div class="textbox1 left-paragraph">
                    
                          <p >
                          I'm Andrew Rizk, an honors undergraduate Computer Science at York University. While my roots lie in coding and algorithms, my life has diverse passions and interests. I'm fueled by an insatiable curiosity for learning, exploring various genres of literature from captivating novels to enlightening personal development 
                          <span 
                            id="books-span"
                            className="books-container-9002 ancer-2004" 
                            onMouseEnter={handleMouseEnter} 
                            onMouseLeave={handleMouseLeave}
                          > books. </span> 
                        {isHovered && (
                        <div 
                          className="dropdown-content-9002" 
                          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
                        >
                          <ul>
                            <li>Think and grow rich - Napoleon Hill</li>
                            <li>Zero to One - Peter Thiel</li>
                            <li>7 habits of highly effective people - R. Covey</li>
                            <li>Rish Dad Poor Dad - Robert Kiyosaki</li>
                            <li>Da Vinci's Code - Dan Brown</li>
                            <li>Angels & Demons - Dan Brown</li>
                            <li>The Cairo Trilogy - Nagib Mahfuz</li>
                            <li>It Ends/Starts With Us - Collen Hover</li>
                            {/* Add more books as needed */}
                          </ul>
                        </div>)}
                          I also enjoy <a className="ancer-2004" onClick={() => scrollToSection(Introduction1)}> gaming, shows and sports.</a> However, what truly sets me apart is my unwavering determination and commitment to my long term goals, with <a className="ancer-2004" href="./projects"> coding new projects</a> always been my main intrest.
                          </p>
                          
                          <br/>
                          <QuoteComponent/>
                          </div>
                          


                          <div ref={photos} class="right-photo">
                          
                            <Slideshow/>
                          </div>
                          
                    </div>
                    
                }
        
        {windowWidth > 1000 ?
        
        <div ref={Introduction1} className="textbox-container2">
        <AstronautFloatAnimation />  
        <h3 style={{fontFamily:"verdana sans-serif", marginTop:"20px", marginLeft: '50px', marginBottom: '-50px',marginTop: '50px', fontSize: "30px"}}>My Interests and hobbies. <span style={{fontSize: "15px"}}>Hover over them to see my favourite parts and highlights</span></h3>
        
        <BoxContainer/>
        
        </div>
        
       :
       <div ref={Introduction1} className="textbox-container2" style={{marginTop: '50px'}}>
        <AstronautFloatAnimation />  

        <h3 style={{fontFamily:"verdana sans-serif", marginTop:"20px", marginLeft: '50px', marginBottom: '-50px', fontSize: "30px"}}>My Interests and hobbies. <span style={{fontSize: "15px"}}>Click them to see my favourite parts and highlights</span></h3>
        <BoxContainer2/>
        
        </div>
}
{windowWidth < 1000 ?
<div style={{color:"transparent",  marginTop:"200px"}}><p>a</p>  </div>
:
<div style={{color:"transparent"}}><p>a</p>  </div>
}
              

      </div>

  
    </div>
  );
};



/* Random Pics */



const Slideshow = () => {
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      plusSlides(1); // Move to the next slide
    }, 10000); // Timer set to 5 seconds

    return () => clearInterval(interval); // Cleanup function to clear the interval
  }, [slideIndex]);
  
  const plusSlides = (n) => {
    const newIndex = slideIndex + n;

    if (newIndex > 10) {
      // Wrap around to the first slide when reaching the end
      setSlideIndex(1);
    } else if (newIndex < 1) {
      // Wrap around to the last slide when going previous from the first slide
      setSlideIndex(10);
    } else {
      setSlideIndex(newIndex);
    }
  };

  const currentSlide = (n) => {
    setSlideIndex(n);
  };

  const showSlides = (n) => {
    const slides = document.getElementsByClassName("mySlides");
    const dots = document.getElementsByClassName("dot");

    if (n > slides.length) {
      setSlideIndex(1);
    }
    if (n < 1) {
      setSlideIndex(slides.length);
    }

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active1", "");
    }

    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active1";
  };

  return (
    <div className="slideshow-container">

    <div className="mySlides fade-9002">
      <div className="numbertext">1 / 10</div>
      <img
        src={Pic1}
        
      />
      <div className="text"></div>
    </div>

    <div className="mySlides fade-9002">
      <div className="numbertext">2 / 10</div>
      <img
        src={Pic2}
        
      />
      <div className="text"></div>
    </div>

    <div className="mySlides fade-9002">
      <div className="numbertext">3 / 10</div>
      <img
        src={Pic3}
        
      />
      <div className="text"></div>
    </div>

    <div className="mySlides fade-9002">
      <div className="numbertext">4 / 10</div>
      <img
        src={Pic4}
        
      />
      <div className="text"></div>
    </div>

    <div className="mySlides fade-9002">
      <div className="numbertext">5 / 10</div>
      <img
        src={Pic5}
      />
      <div className="text"></div>
    </div>

    <div className="mySlides fade-9002">
      <div className="numbertext">6 / 10</div>
      <img
        src={Pic6}
      />
      <div className="text"></div>
    </div>
    
    <div className="mySlides fade-9002">
      <div className="numbertext">7 / 10</div>
      <img
        src={Pic7}
      />
      <div className="text"></div>
    </div>
    
    <div className="mySlides fade-9002">
      <div className="numbertext">8 / 10</div>
      <img
        src={Pic8}
      />
      <div className="text"></div>
    </div>
    
    <div className="mySlides fade-9002">
      <div className="numbertext">9 / 10</div>
      <img
        src={Pic9}
      />
      <div className="text"></div>
    </div>
    
    <div className="mySlides fade-9002">
      <div className="numbertext">10 / 10</div>
      <img
        src={Pic10}
      />
      <div className="text"></div>
    </div>

    <a className="prev" onClick={() => plusSlides(-1)}>
      &#10094;
    </a>
    <a className="next" onClick={() => plusSlides(1)}>
      &#10095;
    </a>
    <br />
    <div style={{ textAlign: 'center' }}>
      <span className="dot" onClick={() => currentSlide(1)}></span>
      <span className="dot" onClick={() => currentSlide(2)}></span>
      <span className="dot" onClick={() => currentSlide(3)}></span>
      <span className="dot" onClick={() => currentSlide(4)}></span>
      <span className="dot" onClick={() => currentSlide(5)}></span>
      <span className="dot" onClick={() => currentSlide(6)}></span>
      <span className="dot" onClick={() => currentSlide(7)}></span>
      <span className="dot" onClick={() => currentSlide(8)}></span>
      <span className="dot" onClick={() => currentSlide(9)}></span>
      <span className="dot" onClick={() => currentSlide(10)}></span>
    </div>
  </div>
  );
};


const QuoteComponent = () => {
  const quotes = [
    {
      quote:
      "The best way to predict your future is to create it",
      author: 'Abraham Lincoln',
      description: 'This quote has been my guiding principle in my personal life and career, driving me towards specific future goals such as beeing in a good shape, working in a top tech company, and starting my own business. By continuously working on my skill set and maintaining a dedicated workout routine, I am confident that I will turn these aspirations into realities through effort and determination.'
    },
    {
      quote:
        'Whoever wants to become great among you must be your servant, and whoever wants to be first must be your last.',
      author: 'Matthew 20:26-27',
      description: 'Jesus\'s teachings taught me that true leadership is helping each other progress. Specifically, standing behind all pushing them rather than advancing alone. By building a community where everyone contributes and grows, we build a collective mastermind that benefits all involved. This mindset not only enhances personal improvement but also creates a culture of mutual support and success, ultimately leading to greater overall achievement.'
    },
    {
    quote: 'Desire is the starting point of all achievement, not a hope, not a wish, but a keen pulsating desire which transcends everything.',
    author: 'Napoleon Hill',
    description: 'This quote from "Think and Grow Rich" resonates deeply with me, highlighting desire as the fundamental catalyst for achievement. Through Napoleon Hill\'s teachings, I\'ve learned that a burning, unwavering desire is crucial for success. It\'s this intense longing, fueled by passion and determination, that propels me forward in my pursuits, driving me to surpass my limits and achieve my goals.'
  },
  {
    quote: 'I have not failed. I\'ve just found 10,000 ways that won\'t work.',
    author: 'Thomas A. Edison',
    description: 'This quote has significantly influenced my journey, reminding me that failure is not the end but rather an essential part of the journey towards success. Sometimes you would feel hopeless looking at your 400 rejection emails or a "wasted summer". It motivates me to embrace what I did wrong and continuesly seek improvement, knowing that every failure brings valuable lessons and opportunities for growth.'
  },
  {
    quote: 'Success is not final, failure is not fatal: it is the courage to continue that counts.',
    author: 'Winston Churchill',
    description: 'emphasizing the importance of continuous determination even after reaching milestones. It reminds me that success is not an endpoint but a continuous process is, and failure does not define me. Instead, it\'s the courage to persist and keep moving forward that truly matters, driving me to strive for excellence beyond my achievements.'
  },
  ];

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);

  const handleClick = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
      setFadeIn(true);
    }, 100); // Adjust the delay if needed
  };

  const { quote, author, description } = quotes[currentQuoteIndex];

  return (
    <div className="fade-9002">
      <button className="quote-next-button-2004" onClick={handleClick}>Another quote</button>
      <p className="title-2004">Here's a favorite quotes:</p>
      <div className={`animated-border-quote ${fadeIn ? 'active' : ''}`}>
        <blockquote className={`otro-blockquote ${fadeIn ? 'active' : ''}`}>
          {quote}
          <span>- {author}</span>
        </blockquote>
      </div>
      <p className={`description-9002 ${fadeIn ? 'active' : ''}`}>{description}</p>
    </div>
  );
};

export default QuoteComponent;



