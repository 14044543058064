import React, { useState, useEffect  } from 'react';
import './styles.css';
import './styles8001.css';
import './styles8002.css';
import { FaExpandArrowsAlt } from "react-icons/fa";
import { BiCollapse } from "react-icons/bi";
import DSV from "./Project photos/DataStructure4.png"
import movie from "./Project photos/Movie recommender11.png"
import cars from "./Project photos/cars4.jpg"
import review from "./Project photos/review5.jpg"
import green from "./Project photos/green fashion2.jpg"
import maze from "./Project photos/maze2.jpg"
import c4_project from "./Project photos/c42.png"
import visionMaster from "./Project photos/VisionMaster.jpg"
import englishify from "./Project photos/Englishify.jpg"
import prediction from "./Project photos/prediction.jpg"
import BSTVisualization  from './DataStructure'
import MovieRecommender from './MovieRecommender'
import Database from './Database.jsx'
import Abouelnile from './Abouelnile.jsx'
import MazeGenerator from './MazeGenerator.jsx'
import VisionMaster from './VisionMaster.jsx'
import Englishify from './Englishify.jsx'
import Prediction from './Prediction.jsx'
import Greenstyle from './Greenstyle.jsx'
import { BiCodeAlt } from "react-icons/bi";
import { AiFillGithub } from "react-icons/ai";
import ReadmeContainer from './ReadMeMovie.jsx';
import ReadmeReviewify from './ReadMeReviewify.jsx';
import ReadMeMaze from './ReadMeMaze.jsx';
import ReadMeVisionMaster from './ReadMeVisionMaster.jsx';
import ReadMeEnglishify from './ReadMeEnglishify.jsx';
import ReadMePrediction from './ReadMePrediction.jsx';
import { FaExternalLinkAlt } from "react-icons/fa";
import Backend from './Reviewify_backend.jsx'
import Frontend from './Reviewify_frontend.jsx'   
import Computer from "../animationpics/projects.jsx";




const Project1 = () => {
  const [expanded, setExpanded] = useState(false);
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleReadMoreClick = () => {
    setReadMoreClicked(!readMoreClicked); // Toggle the state
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    const project1Section = document.getElementById('project1');
    if (project1Section) {
      project1Section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 848);
    };

    handleResize();
 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (expanded) {
    return (
      
      <div id="project1" className={`card-800 body-800 expanded ${readMoreClicked ? 'read-more-clicked' : ''}`}>
       
       {isSmallScreen ? (
      <div className="card-content-8001">
      <div>  
      <div className="card-content-container">
         
        <div className="card-body-8001">
        <div className="card-header-8001">
        <h4 className="card-body__header-8001">Data Structure Visualizer <a className='github-button-800'  target="_blank" href="https://github.com/AndrewidRizk/DataStructureVisualizer"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
        <a className='link-button-800'  target="_blank" href="https://data-structure-visualizer-lac.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
        <span className='Languages_used-8001'>Languages:</span>
        <img alt="Python"  src="https://img.shields.io/badge/Python-14354C.svg?logo=python&logoColor=white"/>
        <img alt="Flask"    src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white"/> 
        <img alt="HTML"    src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
        <img alt="CSS"   src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
        <img alt="D3.js"   src="https://img.shields.io/badge/D3.js-F9A03C.svg?logo=d3.js&logoColor=white"/>
        <button className="card-footer__btn-8001" onClick={handleExpandClick}><BiCollapse  /></button>
    
      </div>
      <div className='details-8001'> 
        <h3>Overview</h3>
          <ul>
          <li>- Interactive visualizations for various data structures: linked lists, stacks, queues, trees, and graphs.</li>
          <li>- Implementation in Python using Flask for visualization in HTML.</li>
          <li>- Dynamic creation, modification, and deletion of data structures through an intuitive interface.</li>
          <li>- Beginner-friendly interface for learning data structures.</li>
          <li>- Responsive design for seamless experience across devices.</li>
          <li>- Real-time execution visualization for algorithms.</li>
          <li>- Educational resources for understanding concepts.</li>
          <li>- Message sending and printing to screen using Flask.</li>
          <li>- Comprehensive error handling for informative feedback.</li>
          <li>- Codebase follows best practices: modularization, commenting, adherence to PEP 8.</li>
          </ul>
          <h3>Binary Search Tree</h3>
          <ul>
            <li><code>• add(data)</code>: Adds a new node with the given data to the BST.</li>
            <li><code>• remove(data)</code>: Removes a node with the given data from the BST.</li>
            <li><code>• searchNode(data)</code>: Searches for a node with the given data in the BST and returns the node value if found.</li>
            <li><code>• inorder_traverse()</code>: Performs an inorder traversal of the BST and stores the result in the <code>inorder_result</code> list.</li>
            <li><code>• preorder_traverse()</code>: Performs a preorder traversal of the BST and stores the result in the <code>preorder_result</code> list.</li>
          </ul>
          <h3>Max Heap Binary Tree</h3>
          <ul>
            <li><code>• insert(value)</code>: Inserts a new value into the Max Heap.</li>
            <li><code>• delete(value)</code>: Deletes a value from the Max Heap.</li>
            <li><code>• make_binary_tree()</code>: Creates a binary tree representation of the Max Heap.</li>
            <li><code>• find_left_child_element(element)</code>: Returns the left child index of the given element.</li>
            <li><code>• find_right_child_element(element)</code>: Returns the right child index of the given element.</li>
          </ul>
          <h3>Min Heap Binary Tree</h3>
          <ul>
            <li><code>• insert(value)</code>: Inserts a new value into the Min Heap.</li>
            <li><code>• delete(value)</code>: Deletes a value from the Min Heap.</li>
            <li><code>• make_binary_tree()</code>: Creates a binary tree representation of the Min Heap.</li>
            <li><code>• find_left_child_element(element)</code>: Returns the left child index of the given element.</li>
            <li><code>• find_right_child_element(element)</code>: Returns the right child index of the given element.</li>
          </ul>
          <h3>Linked Lists</h3>
          <ul>
            <li><code>• insert_at_beginning(data)</code>: Inserts a new node with the given data at the beginning of the linked list.</li>
            <li><code>• insert_at_end(data)</code>: Inserts a new node with the given data at the end of the linked list.</li>
            <li><code>• insert_after_node(prev_node2, data)</code>: Inserts a new node with the given data after a specified previous node.</li>
            <li><code>• delete_node(data)</code>: Deletes a node with the given data from the linked list.</li>
            <li><code>• search(data)</code>: Searches for a node with the given data in the linked list and returns a linked list of steps.</li>
          </ul>
        </div>
        
        </div>

        <div className="video-container">
          <div className="card-footer-8001">
            
          </div>
          
       
            
        </div>
        
        
      </div>
      <iframe className='demo-8001' src="https://www.youtube.com/embed/II4Or79NKkM" frameborder="1" allowfullscreen title="Data Structure Visualization"></iframe>
      
      </div>
      </div>
    
    ) : (
      <div className="card-content-8001">
      <div>  
      <div className="card-content-container">
         
        <div className="card-body-8001">
        <div className="card-header-8001">
        <h4 className="card-body__header-8001">Data Structure Visualizer <a className='github-button-800'  target="_blank" href="https://github.com/AndrewidRizk/DataStructureVisualizer"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
        <a className='link-button-800' target="_blank" href="https://data-structure-visualizer-lac.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
        <span className='Languages_used-8001'>Languages:</span>
        <img alt="Python"  src="https://img.shields.io/badge/Python-14354C.svg?logo=python&logoColor=white"/>
        <img alt="Flask"    src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white"/> 
        <img alt="HTML"    src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
        <img alt="CSS"   src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
        <img alt="D3.js"   src="https://img.shields.io/badge/D3.js-F9A03C.svg?logo=d3.js&logoColor=white"/>

      </div>
      <div className='details-8001'> 
      <h3>Overview</h3>
          <ul>
          <li>- Interactive visualizations for various data structures: linked lists, stacks, queues, trees, and graphs.</li>
          <li>- Implementation in Python using Flask for visualization in HTML.</li>
          <li>- Dynamic creation, modification, and deletion of data structures through an intuitive interface.</li>
          <li>- Beginner-friendly interface for learning data structures.</li>
          <li>- Responsive design for seamless experience across devices.</li>
          <li>- Real-time execution visualization for algorithms.</li>
          <li>- Educational resources for understanding concepts.</li>
          <li>- Message sending and printing to screen using Flask.</li>
          <li>- Comprehensive error handling for informative feedback.</li>
          <li>- Codebase follows best practices: modularization, commenting, adherence to PEP 8.</li>
          </ul>
          <h3>Binary Search Tree</h3>
          <ul>
            <li><code>• add(data)</code>: Adds a new node with the given data to the BST.</li>
            <li><code>• remove(data)</code>: Removes a node with the given data from the BST.</li>
            <li><code>• searchNode(data)</code>: Searches for a node with the given data in the BST and returns the node value if found.</li>
            <li><code>• inorder_traverse()</code>: Performs an inorder traversal of the BST and stores the result in the <code>inorder_result</code> list.</li>
            <li><code>• preorder_traverse()</code>: Performs a preorder traversal of the BST and stores the result in the <code>preorder_result</code> list.</li>
          </ul>
          <h3>Max Heap Binary Tree</h3>
          <ul>
            <li><code>• insert(value)</code>: Inserts a new value into the Max Heap.</li>
            <li><code>• delete(value)</code>: Deletes a value from the Max Heap.</li>
            <li><code>• make_binary_tree()</code>: Creates a binary tree representation of the Max Heap.</li>
            <li><code>• find_left_child_element(element)</code>: Returns the left child index of the given element.</li>
            <li><code>• find_right_child_element(element)</code>: Returns the right child index of the given element.</li>
          </ul>
          <h3>Min Heap Binary Tree</h3>
          <ul>
            <li><code>• insert(value)</code>: Inserts a new value into the Min Heap.</li>
            <li><code>• delete(value)</code>: Deletes a value from the Min Heap.</li>
            <li><code>• make_binary_tree()</code>: Creates a binary tree representation of the Min Heap.</li>
            <li><code>• find_left_child_element(element)</code>: Returns the left child index of the given element.</li>
            <li><code>• find_right_child_element(element)</code>: Returns the right child index of the given element.</li>
          </ul>
          <h3>Linked Lists</h3>
          <ul>
            <li><code>• insert_at_beginning(data)</code>: Inserts a new node with the given data at the beginning of the linked list.</li>
            <li><code>• insert_at_end(data)</code>: Inserts a new node with the given data at the end of the linked list.</li>
            <li><code>• insert_after_node(prev_node2, data)</code>: Inserts a new node with the given data after a specified previous node.</li>
            <li><code>• delete_node(data)</code>: Deletes a node with the given data from the linked list.</li>
            <li><code>• search(data)</code>: Searches for a node with the given data in the linked list and returns a linked list of steps.</li>
          </ul>
        </div>
        
        </div>

        <div className="video-container">
          <div className="card-footer-8001">
            <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
          </div>
          
        <iframe className='demo-8001' src="https://www.youtube.com/embed/II4Or79NKkM" frameborder="1" allowfullscreen title="Data Structure Visualization"></iframe>
            
        </div>
        
        
      </div>
      <h4 className="card-body_2_header-8001">Try it yourself</h4>
            <BSTVisualization />
      </div>
      </div>
    )}
    </div>
    
);
  } else {
    return (
      <div id="project1" className={`card-800 body-800 ${readMoreClicked ? 'read-more-clicked' : ''}`}>
        <div className="card-content-800">
          <div className="card-header-800">
            <h4 className="card-body__header-800">Data Structure Visualizer</h4>
            <p className="card-body__lang-800">
            <img alt="Python"   className='logo-800' src="https://img.shields.io/badge/Python-14354C.svg?logo=python&logoColor=white"/>
            <img alt="Flask"    className='logo-800' src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white"/> 
            <img alt="HTML"     className='logo-800' src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
            <img alt="CSS"      className='logo-800' src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
            <img alt="D3.js"    className='logo-800' src="https://img.shields.io/badge/D3.js-F9A03C.svg?logo=d3.js&logoColor=white"/>
            </p>
            <img src={DSV} alt="" className="card-header__image-800" />
          </div>
          <div className="card-body-800">
            <p className="card-body__desc-800">Designed and implemented a data structure website using Flask, allowing users to visualize and interact with various data structures such as Binary Search Tree (BST), Max Heap, and Min Heap in an arcade-like theme.</p>
            
          </div>
          <div className="card-footer-800">
            <button className="card-footer__btn-800" onClick={handleExpandClick}>Expand <FaExpandArrowsAlt /></button>
            <a className='github-button-800'  target="_blank" href="https://github.com/AndrewidRizk/DataStructureVisualizer"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
            <a className='link-button-800'  target="_blank" href="https://data-structure-visualizer-lac.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a>
          </div>
        </div>
      </div>
    );
  }
};



const Project2 = () => {
  const [expanded, setExpanded] = useState(false);
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleReadMoreClick = () => {
    setReadMoreClicked(!readMoreClicked); // Toggle the state
  };

  
  
  const handleExpandClick = () => {
    
      setExpanded(!expanded);
      setTimeout(() => {
      const project2Section = document.getElementById('project2');
      if (project2Section) {
        project2Section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 150); // 1500 milliseconds = 1.5 seconds
  };
  

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 848);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (expanded) {
    return (
      
      <div id="project2" className={`card-800 body-800 expanded ${readMoreClicked ? 'read-more-clicked' : ''}`}>
       
       {isSmallScreen ? (
      <div className="card-content-8001">
      <div>  
      <div className="card-content-container">
         
        <div className="card-body-8001">
        <div className="card-header-8001">
        <h4 className="card-body__header-8001">Movie Recommender <a className='github-button-800'  target="_blank" href="https://github.com/AndrewidRizk/Movie-Recommender"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
        <a className='link-button-800'  target="_blank" href="https://movie-recommender-bice.vercel.app//"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
        <span className='Languages_used-8001'>Languages:</span>
        <img alt="Python"    src="https://img.shields.io/badge/Python-14354C.svg?logo=python&logoColor=white"/>
        <img alt="Flask"     src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white"/> 
        <img alt="MySQL"     src="https://img.shields.io/badge/MySQL-4479A1.svg?logo=mysql&logoColor=white"/>
        <img alt="HTML"      src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
        <img alt="CSS"       src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
        <button className="card-footer__btn-8001" onClick={handleExpandClick}><BiCollapse  /></button>
    
      </div>
      <div className='details-8001'> 
      <ReadmeContainer/>
        </div>
        
        </div>

        <div className="video-container">
          <div className="card-footer-8001">
            
          </div>
          
       
            
        </div>
        
        
      </div>
      <iframe className='demo-8001' src="https://www.youtube.com/embed/wVKVFUkXisA" frameborder="1" allowfullscreen title="Data Structure Visualization"></iframe>
      
      </div>
      </div>
    
    ) : (
      <div className="card-content-8001">
      <div>  
      <div className="card-content-container">
         
        <div className="card-body-8001">
        <div className="card-header-8001">
        <h4 className="card-body__header-8001">Movie Recommender <a className='github-button-800'  target="_blank"  href="https://github.com/AndrewidRizk/Movie-Recommender"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
        <a className='link-button-800'  target="_blank" href="https://movie-recommender-bice.vercel.app//"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
        <span className='Languages_used-8001'>Languages:</span>
        <img alt="Python"   className='logo-800' src="https://img.shields.io/badge/Python-14354C.svg?logo=python&logoColor=white"/>
        <img alt="Flask"    className='logo-800' src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white"/> 
        <img alt="MySQL"    className='logo-800' src="https://img.shields.io/badge/MySQL-4479A1.svg?logo=mysql&logoColor=white"/>
        <img alt="HTML"     className='logo-800' src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
        <img alt="CSS"      className='logo-800' src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>

      </div>
      <div className='details-8001'> 
          <ReadmeContainer/>
      </div>
        
        </div>

        <div className="video-container">
          <div className="card-footer-8001">
            <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
          </div>
          
        <Database className=''/>
            
        </div>
        
        
      </div>
      <h4 className="card-body_2_header-8001">Try it yourself</h4>
            <MovieRecommender />
      </div>
      </div>
    )}
    </div>
    
);
  } else {
  return (
    <div id="project2" className={`card-800 body-800 ${expanded ? 'expanded' : ''} ${readMoreClicked ? 'read-more-clicked' : ''}`}>
      <div className="card-content-800">
        <div className="card-header-800">
        <h4 className="card-body__header-800">Movie Recommender</h4>
        <p className="card-body__lang-800">
            <img alt="Python"   className='logo-800' src="https://img.shields.io/badge/Python-14354C.svg?logo=python&logoColor=white"/>
            <img alt="Flask"    className='logo-800' src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white"/> 
            <img alt="MySQL"    className='logo-800' src="https://img.shields.io/badge/MySQL-4479A1.svg?logo=mysql&logoColor=white"/>
            <img alt="HTML"     className='logo-800' src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
            <img alt="CSS"      className='logo-800' src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
          </p>
          <img src={movie} alt="" className="card-header__image-800" />
        </div>
        <div className="card-body-800">
          <p className="card-body__desc-800">Developed a secure web app with user sign-up/login, watchlist management, and personalized movie recommendations using TMDB and YouTube API, with data securely stored in a MySQL database.</p>
        </div>
        <div className="card-footer-800">
          <button   className="card-footer__btn-800" onClick={handleExpandClick}>Expand <FaExpandArrowsAlt /></button>
          <a className='github-button-800'  target="_blank" href="https://github.com/AndrewidRizk/Movie-Recommender"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a>
          <a className='link-button-800'  target="_blank" href="https://movie-recommender-bice.vercel.app//"> <FaExternalLinkAlt className='icon-800 link-800'/></a>
        </div>
      </div>
    </div>
  );
  }
};


const Project3 = () => {
  const [expanded, setExpanded] = useState(false);
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);


  const handleReadMoreClick = () => {
    setReadMoreClicked(!readMoreClicked); // Toggle the state
  };

  const handleExpandClick = () => {
    
    setExpanded(!expanded);
    setTimeout(() => {
    const project3Section = document.getElementById('project3');
    if (project3Section) {
      project3Section.scrollIntoView({ behavior: 'smooth' });
    }
  }, 60); // 1500 milliseconds = 1.5 seconds
};
  
useEffect(() => {
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 848);
  };

  handleResize();

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

if (expanded) {
  return (
    
    <div id="project3" className={`card-800 body-800 expanded ${readMoreClicked ? 'read-more-clicked' : ''}`}>
     
     {isSmallScreen ? (
    <div className="card-content-8001">
    <div>  
    <div className="card-content-container">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">AbouelNile Motors<a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/AbouelNileMotors"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="https://andrewidrizk.github.io/AbouelNileMotors/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="HTML"     src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
      <img alt="CSS"      src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
      <img alt="JavaScript"  src="https://img.shields.io/badge/JavaScript-F7DF1E?logo=javascript&logoColor=black"/>
      <button className="card-footer__btn-8001" onClick={handleExpandClick}><BiCollapse  /></button>
  
    </div>
    <div className='details-8001' > 
    <h3>Requirements Gathering:</h3>
        <ul>
          <li>- Understand the needs and goals of AbouelNile Motors for their website.</li>
          <li>- Determine the target audience and their preferences.</li>
          <li>- Gather information about the services offered, pricing, contact details, and any special offers.</li>
        </ul>
        <h3>Analysis:</h3>
        <ul>
          <li>- Analyze competitors' websites to identify strengths and weaknesses.</li>
          <li>- Determine the key features and functionalities needed for the website.</li>
          <li>- Decide on the layout, color scheme, and overall design aesthetic.</li>
          <li>- It was decided to make a simple website for distribution with no complicated backend or database.</li>
        </ul>
        <h3>Design:</h3>
        <ul>
          <li>- Create wireframes and mockups using Figma to visualize the website's structure and design.</li>
          <li>- Look for examples online and already existing free templates to fasten the process.</li>
          <li>- Design a user-friendly interface and logo that aligns with the branding of AbouelNile Motors.</li>
          <li>- Ensure responsiveness across various devices and screen sizes.</li>
        </ul>
        <h3>Development:</h3>
        <ul>
          <li>- Use simple HTML, CSS, and JavaScript to build the website.</li>
          <li>- Develop a navigation system that is intuitive and easy to use.</li>
          <li>- Implement features such as image galleries and service descriptions.</li>
          <li>- Optimize the website for fast loading times and search engine visibility.</li>
          <li>- Test the website's functionality and fix any bugs or errors.</li>
          <li>- Introduce added animated scrolling techniques.</li>
        </ul>
        <h3>Deployment:</h3>
        <ul>
          <li>- Deploy the website on GitHub as it was currently the cheapest option for the family business.</li>
          <li>- Set up analytics tools to track website performance and user engagement.</li>
          <li>- Make the website live and accessible to the public.</li>
        </ul>
        <h3>Maintenance:</h3>
        <ul>
          <li>- Provide ongoing support and maintenance to address any issues that arise.</li>
          <li>- Regularly update content and features to keep the website fresh and engaging.</li>
          <li>- Monitor website traffic and performance metrics to identify areas for improvement.</li>
          <li>- Implement security measures to protect against potential threats.</li>
        </ul>
      </div>
      
      </div>

      <div className="video-container">
        <div className="card-footer-8001">
        <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
        </div>
        
     
          
      </div>
      
      
    </div>
    <Abouelnile />
    
    </div>
    </div>
  
  ) : (
    <div className="card-content-8001">
    <div>  
    <div className="card-content-container">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">AbouelNile Motors<a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/AbouelNileMotors"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="https://andrewidrizk.github.io/AbouelNileMotors/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="HTML"     className='logo-800' src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
      <img alt="CSS"      className='logo-800' src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
      <img alt="JavaScript" className='logo-800' src="https://img.shields.io/badge/JavaScript-F7DF1E?logo=javascript&logoColor=black"/>


    </div>
    <div className='details-8002'> 
    <h3>Requirements Gathering:</h3>
        <ul>
          <li>- Understand the needs and goals of AbouelNile Motors for their website.</li>
          <li>- Determine the target audience and their preferences.</li>
          <li>- Gather information about the services offered, pricing, contact details, and any special offers.</li>
        </ul>
        <h3>Analysis:</h3>
        <ul>
          <li>- Analyze competitors' websites to identify strengths and weaknesses.</li>
          <li>- Determine the key features and functionalities needed for the website.</li>
          <li>- Decide on the layout, color scheme, and overall design aesthetic.</li>
          <li>- It was decided to make a simple website for distribution with no complicated backend or database.</li>
        </ul>
        <h3>Design:</h3>
        <ul>
          <li>- Create wireframes and mockups using Figma to visualize the website's structure and design.</li>
          <li>- Look for examples online and already existing free templates to fasten the process.</li>
          <li>- Design a user-friendly interface and logo that aligns with the branding of AbouelNile Motors.</li>
          <li>- Ensure responsiveness across various devices and screen sizes.</li>
        </ul>
        <h3>Development:</h3>
        <ul>
          <li>- Use simple HTML, CSS, and JavaScript to build the website.</li>
          <li>- Develop a navigation system that is intuitive and easy to use.</li>
          <li>- Implement features such as image galleries and service descriptions.</li>
          <li>- Optimize the website for fast loading times and search engine visibility.</li>
          <li>- Test the website's functionality and fix any bugs or errors.</li>
          <li>- Introduce added animated scrolling techniques.</li>
        </ul>
        <h3>Deployment:</h3>
        <ul>
          <li>- Deploy the website on GitHub as it was currently the cheapest option for the family business.</li>
          <li>- Set up analytics tools to track website performance and user engagement.</li>
          <li>- Make the website live and accessible to the public.</li>
        </ul>
        <h3>Maintenance:</h3>
        <ul>
          <li>- Provide ongoing support and maintenance to address any issues that arise.</li>
          <li>- Regularly update content and features to keep the website fresh and engaging.</li>
          <li>- Monitor website traffic and performance metrics to identify areas for improvement.</li>
          <li>- Implement security measures to protect against potential threats.</li>
        </ul>
    </div>
      
      </div>

      
        <div className="card-footer-8001">
          <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
      </div>
      
      
    </div>
    <h4 className="card-body_2_header-8001">Try it yourself</h4>
          <Abouelnile />
    </div>
    </div>
  )}
  </div>
  
);
  } else {
  return (
    <div id="project3" className={`card-800 body-800 ${expanded ? 'expanded' : ''} ${readMoreClicked ? 'read-more-clicked' : ''}`}>
       <div className="card-content-800">
        <div className="card-header-800">
        <h4 className="card-body__header-800">AbouelNile Motors </h4>
        <p className="card-body__lang-800">
        <img alt="HTML"     className='logo-800' src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
        <img alt="CSS"      className='logo-800' src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
        <img alt="JavaScript" className='logo-800' src="https://img.shields.io/badge/JavaScript-F7DF1E?logo=javascript&logoColor=black"/>
        </p>
        <img src={cars} alt="" className="card-header__image-800" />
        </div>
        <div className="card-body-800">
          <p className="card-body__desc-800">
          Developed and deployed the AbouelNile Motors website using HTML, CSS, and JavaScript, following the full SDLC, with features including interactive service listings, pricing, galleries, and online booking for customers.</p>
        </div>
        <div className="card-footer-800">
          <button className="card-footer__btn-800" onClick={handleExpandClick}>Expand <FaExpandArrowsAlt /></button>
          <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/AbouelNileMotors"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a>
          <a className='link-button-800' target="_blank" href="https://andrewidrizk.github.io/AbouelNileMotors/"> <FaExternalLinkAlt className='icon-800 link-800'/></a>
        </div>
      </div>
    </div>
  );
  }
};


const Project4 = () => {
  const [expanded, setExpanded] = useState(false);
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);


  const handleReadMoreClick = () => {
    setReadMoreClicked(!readMoreClicked); // Toggle the state
  };

  const handleExpandClick = () => {
    
    setExpanded(!expanded);
    setTimeout(() => {
    const project4Section = document.getElementById('project4');
    if (project4Section) {
      project4Section.scrollIntoView({ behavior: 'smooth' });
    }
  }, 70); // 1500 milliseconds = 1.5 seconds
};
useEffect(() => {
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 848);
  };

  handleResize();

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

if (expanded) {
  return (
    
    <div id="project4" className={`card-800 body-800 expanded ${readMoreClicked ? 'read-more-clicked' : ''}`}>
     
     {isSmallScreen ? (
    <div className="card-content-8001">
    <div>  
    <div className="card-content-container">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">Reviewify <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Reviewify-Business"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="https://reviewify-frontend.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="Python"  src="https://img.shields.io/badge/Python-14354C?logo=python&logoColor=white"/>
          <img alt="Flask"  src="https://img.shields.io/badge/Flask-000000?&logo=flask&logoColor=white"/>
          <img alt="React.js" src="https://img.shields.io/badge/React.js-61DAFB?&logo=react&logoColor=white"/>
          <img alt="Tailwind CSS"  src="https://img.shields.io/badge/Tailwind%20CSS-38B2AC?&logo=tailwind-css&logoColor=white"/>
      <button className="card-footer__btn-8001" onClick={handleExpandClick}><BiCollapse  /></button>
  
    </div>
    <div className='details-8001'> 
    <ReadmeReviewify/>
      </div>
      
      </div>

      <div className="video-container">
        <div className="card-footer-8001">
          
        </div>
        
     
          
      </div>
      
      
    </div>
    <iframe className='demo-8001' src="https://www.youtube.com/embed/ExPOx_9WLb8" frameborder="1" allowfullscreen title="Data Structure Visualization"></iframe>
    
    </div>
    </div>
  
  ) : (
    <div className="card-content-8001">
    <div>  
    <div className="card-content-container">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">Reviewify <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Reviewify-Business"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="https://reviewify-frontend.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-14354C?logo=python&logoColor=white"/>
          <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?&logo=flask&logoColor=white"/>
          <img alt="React.js" className='logo-800' src="https://img.shields.io/badge/React.js-61DAFB?&logo=react&logoColor=white"/>
          <img alt="Tailwind CSS" className='logo-800' src="https://img.shields.io/badge/Tailwind%20CSS-38B2AC?&logo=tailwind-css&logoColor=white"/>
    </div>
    <div className='details-8001'> 
    <ReadmeReviewify/>
      </div>
      
      </div>

      <div className="video-container">
          <div className="card-footer-8001">
            <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
          </div>
          
        <Backend className=''/> 
            
        </div>
      
      
    </div>
    <h4 className="card-body_2_header-8001">Try it yourself</h4>
          <Frontend />
    </div>
    </div>
  )}
  </div>
    );
  } else {
  return (
    <div id="project4" className={`card-800 body-800 ${expanded ? 'expanded' : ''} ${readMoreClicked ? 'read-more-clicked' : ''}`}>
      <div className="card-content-800">
      <div className="card-header-800">
        <h4 className="card-body__header-800">Reviewify</h4>
        <p className="card-body__lang-800">
          <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-14354C?logo=python&logoColor=white"/>
          <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?&logo=flask&logoColor=white"/>
          <img alt="React.js" className='logo-800' src="https://img.shields.io/badge/React.js-61DAFB?&logo=react&logoColor=white"/>
          <img alt="Tailwind CSS" className='logo-800' src="https://img.shields.io/badge/Tailwind%20CSS-38B2AC?&logo=tailwind-css&logoColor=white"/>

        </p>
      
          <img src={review} alt="" className="card-header__image-800" />
        </div>
        <div className="card-body-800">
          <p className="card-body__desc-800">Collaborated on a web app that analyzes Google Reviews using Cohere’s APIs, engineered a Redis database, utilized Google’s Geolocation and Places APIs, and developed a React frontend with a Flask backend.</p>
        </div>
        <div className="card-footer-800">
          <button className="card-footer__btn-800" onClick={handleExpandClick}>Expand <FaExpandArrowsAlt /></button>
          <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Reviewify-Business"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a>
          <a className='link-button-800' target="_blank" href="https://reviewify-frontend.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a>
        </div>
      </div>
    </div>
  );
  }
};



const Project6 = () => {
  const [expanded, setExpanded] = useState(false);
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 848);
    };
  
    handleResize();
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleReadMoreClick = () => {
    setReadMoreClicked(!readMoreClicked); // Toggle the state
  };

  const handleExpandClick = () => {
    
    setExpanded(!expanded);
    setTimeout(() => {
    const project6Section = document.getElementById('project6');
    if (project6Section) {
      project6Section.scrollIntoView({ behavior: 'smooth' });
    }
  }, 70); // 1500 milliseconds = 1.5 seconds
};
if (expanded) {
  return (
    
    <div id="project6" className={`card-800 body-800 expanded ${readMoreClicked ? 'read-more-clicked' : ''}`}>
     
     {isSmallScreen ? (
    <div className="card-content-8001">
    <div>  
    <div className="card-content-container">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">Green Style
      <a className='link-button-800' href="https://drdav91.wixsite.com/mysite">
      <a className='github-button-800' href="https://github.com/AndrewidRizk/GreenStyle"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a>  <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages and Tools:</span>
      <img alt="Wix" src="https://img.shields.io/badge/Wix-5C24CC?logo=wix&logoColor=white"/>
        <img alt="HTML"   src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white" />
        <img alt="CSS" src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
        <img alt="JavaScript" src="https://img.shields.io/badge/JavaScript-F7DF1E?logo=javascript&logoColor=black"/>
      <button className="card-footer__btn-8001" onClick={handleExpandClick}><BiCollapse  /></button>
  
    </div>
    <div className='details-8001'> 
    <br/>
    <h3>Winner of the Sustainability Award <a target="_blank" href="https://www.yorku.ca/c4/events/summer-capstone-day-2023/"><FaExternalLinkAlt className='link-button-800 icon-800 link-800'/></a></h3>
      <br/>
      <p>
      The project began by addressing the prevalent issue of fast fashion and its detrimental environmental effects. As the sole developer in a team of six, we recognized the urgency of tackling the youth's lack of awareness about fast fashion. Leveraging Figma, we engaged in extensive design sessions to conceptualize our solution. Subsequently, we embarked on a mission to gather feedback from high school educators to develop an educational plan aimed at combatting this issue.
      <br/>
      </p>
      <br/> 
      <p>
        In response to the problem, as the sole developer in a team of six, we crafted an educational platform designed to enlighten the youth about the ramifications of fast fashion. Using Figma to build prototypes and Utilizing Wix for website development and hosting, we implemented manual JavaScript and CSS to tackle complex tasks, such as interactive features and custom styling. Wix proved instrumental in our development process, offering an intuitive interface and rapid deployment capabilities perfectly suited to our limited time which was less than a week.
      </p>
      <br/>
      <p>
      The resulting website boasts a plethora of resources and engaging content meticulously crafted to impart knowledge and foster awareness among our target audience. Our ultimate goal is to catalyze systemic change by advocating for the integration of our educational material into the Ontario curriculum. By garnering the attention of the Ministry of Education of Ontario, we aim to lay the groundwork for a greener future.
      </p>
      </div>
      
      </div>

      <div className="video-container">
        <div className="card-footer-8001">
        <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
        </div>
        
     
          
      </div>
      
      
    </div>
    <Greenstyle />
    
    </div>
    </div>
  
  ) : (
    <div className="card-content-8001">
    <div>  
    <div className="card-content-container">
         
        <div className="card-body-8001">
        <div className="card-header-8001">
        <h4 className="card-body__header-8001">Green Style 
      <a className='link-button-800' target="_blank" href="https://drdav91.wixsite.com/mysite"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages and Tools:</span>
      <img alt="Wix"className='logo-800'src="https://img.shields.io/badge/Wix-5C24CC?logo=wix&logoColor=white"/>
        <img alt="HTML" className='logo-800' src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white" />
        <img alt="CSS"className='logo-800'src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
        <img alt="JavaScript"className='logo-800'src="https://img.shields.io/badge/JavaScript-F7DF1E?logo=javascript&logoColor=black"/>
      </div>
      <div className='details-8001'> 
      <h3>Winner of the Sustainability Award <a target="_blank" href="https://www.yorku.ca/c4/events/summer-capstone-day-2023/"><FaExternalLinkAlt className='link-button-800 icon-800 link-800'/></a></h3>
      <br/>
      <p>
      The project began by addressing the prevalent issue of fast fashion and its detrimental environmental effects. As the sole developer in a team of six, we recognized the urgency of tackling the youth's lack of awareness about fast fashion. Leveraging Figma, we engaged in extensive design sessions to conceptualize our solution. Subsequently, we embarked on a mission to gather feedback from high school educators to develop an educational plan aimed at combatting this issue.
      <br/>
      </p>
      <br/> 
      <p>
        In response to the problem, as the sole developer in a team of six, we crafted an educational platform designed to enlighten the youth about the ramifications of fast fashion. Using Figma to build prototypes and Utilizing Wix for website development and hosting, we implemented manual JavaScript and CSS to tackle complex tasks, such as interactive features and custom styling. Wix proved instrumental in our development process, offering an intuitive interface and rapid deployment capabilities perfectly suited to our limited time which was less than a week.
      </p>
      <br/>
      <p>
      The resulting website boasts a plethora of resources and engaging content meticulously crafted to impart knowledge and foster awareness among our target audience. Our ultimate goal is to catalyze systemic change by advocating for the integration of our educational material into the Ontario curriculum. By garnering the attention of the Ministry of Education of Ontario, we aim to lay the groundwork for a greener future.
      </p>

      </div>
        
        </div>

        <div className="video-container">
        
          <div className="card-footer-8001">
          <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
          </div>
          
          <img src={c4_project} alt="" className="c4-image-8001" />
        </div>
        
        
      </div>
      
        
      
    
    <h4 className="card-body_2_header-8001">Try it yourself</h4>
          <Greenstyle />
    </div>
    </div>
  )}
  </div>
  
);
  } else {
  return (
    <div id="project6" className={`card-800 body-800 ${expanded ? 'expanded' : ''} ${readMoreClicked ? 'read-more-clicked' : ''}`}>
      <div className="card-content-800">
      <div className="card-header-800">
        <h4 className="card-body__header-800">Green Style</h4>
        <p className="card-body__lang-800">
        <img alt="Wix"className='logo-800'src="https://img.shields.io/badge/Wix-5C24CC?logo=wix&logoColor=white"/>
        <img alt="HTML" className='logo-800' src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white" />
        <img alt="CSS"className='logo-800'src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
        <img alt="JavaScript"className='logo-800'src="https://img.shields.io/badge/JavaScript-F7DF1E?logo=javascript&logoColor=black"/>
        </p>
          <img src={green} alt="" className="card-header__image-800" />
        </div>
        <div className="card-body-800">
          <p className="card-body__desc-800">Recognized with the Sustainability Award for promoting environmental awareness through the GreenStyle project; developed a user-friendly website as the primary educational platform, collaborating with a team of five to achieve project goals.</p>
        </div>
        <div className="card-footer-800">
          <button   className="card-footer__btn-800" onClick={handleExpandClick}>Expand <FaExpandArrowsAlt /></button>
          
          <a className='link-button-800' target="_blank" href="https://drdav91.wixsite.com/mysite"> <FaExternalLinkAlt className='icon-800 link-800'/></a>
        </div>
      </div>
    </div>
  );
  }
};

const Project7 = () => {
  const [expanded, setExpanded] = useState(false);
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);


  const handleReadMoreClick = () => {
    setReadMoreClicked(!readMoreClicked); // Toggle the state
  };

  const handleExpandClick = () => {
    
    setExpanded(!expanded);
    setTimeout(() => {
    const project3Section = document.getElementById('project7');
    if (project3Section) {
      project3Section.scrollIntoView({ behavior: 'smooth' });
    }
  }, 80); // 1500 milliseconds = 1.5 seconds
};
  
useEffect(() => {
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 848);
  };

  handleResize();

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

if (expanded) {
  return (
    
    <div id="project7" className={`card-800 body-800 expanded ${readMoreClicked ? 'read-more-clicked' : ''}`}>
     
     {isSmallScreen ? (
    <div className="card-content-8001 ">
    <div>  
    <div className="card-content-container last-project-8001">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">Maze Generator <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/A-Maze-Generator-and-visualizer"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="https://a-maze-generator-and-visualizer.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="Java"  src="https://img.shields.io/badge/Java-007396?logo=java&logoColor=white"/>
      <img alt="HTML"      src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
      <img alt="CSS"       src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
      <img alt="JavaScript"  src="https://img.shields.io/badge/JavaScript-F7DF1E?logo=javascript&logoColor=black"/>
      <button className="card-footer__btn-8001" onClick={handleExpandClick}><BiCollapse  /></button>
  
    </div>
    <div className='details-8001'> 
    <ReadMeMaze/>
      </div>
      
      </div>

      <div className="video-container">
        <div className="card-footer-8001">
        <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
        </div>
        
     
          
      </div>
      
      
    </div>
    <MazeGenerator />
    
    </div>
    </div>
   
  ) : (
    <div className="card-content-8001">
    <div>  
    <div className="card-content-container">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">Maze Generator <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/A-Maze-Generator-and-visualizer"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="https://a-maze-generator-and-visualizer.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="Java" className='logo-800' src="https://img.shields.io/badge/Java-007396?logo=java&logoColor=white"/>
      <img alt="HTML"     className='logo-800' src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
      <img alt="CSS"      className='logo-800' src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
      <img alt="JavaScript" className='logo-800' src="https://img.shields.io/badge/JavaScript-F7DF1E?logo=javascript&logoColor=black"/>


    </div>
    <div className='details-8002'> 
    <ReadMeMaze/>
    </div>
      
      </div>

      
        <div className="card-footer-8001">
          <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
      </div>
      
      
    </div>
    <h4 className="card-body_2_header-8001">Try it yourself</h4>
          <MazeGenerator />
    </div>
    </div>
  )}
  </div>
  
);
  } else {
  return (
    <div id="project7" className={`card-800 body-800 ${expanded ? 'expanded' : ''} ${readMoreClicked ? 'read-more-clicked' : ''}`}>
      <div className="card-content-800">
      <div className="card-header-800">
        <h4 className="card-body__header-800">Maze Generator </h4>
        <p className="card-body__lang-800">
        <img alt="Java" className='logo-800' src="https://img.shields.io/badge/Java-007396?logo=java&logoColor=white"/>
        <img alt="HTML"     className='logo-800' src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
        <img alt="CSS"      className='logo-800' src="https://img.shields.io/badge/CSS-1572B6.svg?logo=css3&logoColor=white"/>
        <img alt="JavaScript" className='logo-800' src="https://img.shields.io/badge/JavaScript-F7DF1E?logo=javascript&logoColor=black"/>
        </p>
          <img src={maze} alt="" className="card-header__image-800" />
        </div>
        <div className="card-body-800">
          <p className="card-body__desc-800">Created a Java-based API to solve mazes, building on a second-year university project, with a maze generator algorithm that produces mazes based on specified dimensions and finds a pathway from entrance to exit using a 2D array of strings.</p>
        </div>
        <div className="card-footer-800">
        <button className="card-footer__btn-800" onClick={handleExpandClick}>Expand <FaExpandArrowsAlt /></button>
          <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/A-Maze-Generator-and-visualizer"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a>
          <a className='link-button-800' target="_blank" href="https://a-maze-generator-and-visualizer.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a>
        
        </div>
      </div>
    </div>
  );
  }
};





const Project8 = () => {
  const [expanded, setExpanded] = useState(false);
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);


  const handleReadMoreClick = () => {
    setReadMoreClicked(!readMoreClicked); // Toggle the state
  };

  const handleExpandClick = () => {
    
    setExpanded(!expanded);
    setTimeout(() => {
    const project3Section = document.getElementById('project8');
    if (project3Section) {
      project3Section.scrollIntoView({ behavior: 'smooth' });
    }
  }, 80); // 1500 milliseconds = 1.5 seconds
};
  
useEffect(() => {
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 848);
  };

  handleResize();

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

if (expanded) {
  return (
    
    <div id="project8" className={`card-800 body-800 expanded ${readMoreClicked ? 'read-more-clicked' : ''}`}>
     
     {isSmallScreen ? (
    <div className="card-content-8001 ">
    <div>  
    <div className="card-content-container last-project-8001">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">VisionMaster <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Object-Detection-ML"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="http://74.12.132.65:5000/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-3776AB?logo=python&logoColor=white"/>
        <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white"/>
        <img alt="Torch" className='logo-800' src="https://img.shields.io/badge/PyTorch-EE4C2C?logo=pytorch&logoColor=white"/>
        <img alt="TorchVision" className='logo-800' src="https://img.shields.io/badge/TorchVision-FF6F00?logo=pytorch&logoColor=white"/>
      <button className="card-footer__btn-8001" onClick={handleExpandClick}><BiCollapse  /></button>
  
    </div>
    <div className='details-8001'> 
    <ReadMeVisionMaster/>
      </div>
      
      </div>

      <div className="video-container">
        <div className="card-footer-8001">
        <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
        </div>
        
     
          
      </div>
      
      
    </div>
    <VisionMaster />
    
    </div>
    </div>
   
  ) : (
    <div className="card-content-8001">
    <div>  
    <div className="card-content-container">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">VisionMaster <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Object-Detection-ML"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="http://74.12.132.65:5000/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-3776AB?logo=python&logoColor=white"/>
        <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white"/>
        <img alt="Torch" className='logo-800' src="https://img.shields.io/badge/PyTorch-EE4C2C?logo=pytorch&logoColor=white"/>
        <img alt="TorchVision" className='logo-800' src="https://img.shields.io/badge/TorchVision-FF6F00?logo=pytorch&logoColor=white"/>


    </div>
    <div className='details-8002'> 
    <ReadMeVisionMaster/>
    </div>
      
      </div>

      
        <div className="card-footer-8001">
          <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
      </div>
      
      
    </div>
    <h4 className="card-body_2_header-8001">Try it yourself</h4>
          <VisionMaster />
    </div>
    </div>
  )}
  </div>
  
);
  } else {
  return (
    <div id="project8" className={`card-800 body-800 ${expanded ? 'expanded' : ''} ${readMoreClicked ? 'read-more-clicked' : ''}`}>
      <div className="card-content-800">
      <div className="card-header-800">
        <h4 className="card-body__header-800">VisionMaster </h4>
        <p className="card-body__lang-800">
        <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-3776AB?logo=python&logoColor=white"/>
        <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white"/>
        <img alt="Torch" className='logo-800' src="https://img.shields.io/badge/PyTorch-EE4C2C?logo=pytorch&logoColor=white"/>
        <img alt="TorchVision" className='logo-800' src="https://img.shields.io/badge/TorchVision-FF6F00?logo=pytorch&logoColor=white"/>
        </p>
          <img src={visionMaster} alt="" className="card-header__image-800" />
        </div>
        <div className="card-body-800">
          <p className="card-body__desc-800">Developed a custom object detection model with Faster R-CNN on the COCO dataset, optimized to classify and localize various objects within complex images, handling diverse scenarios like occlusion and low lighting.</p>
        </div>
        <div className="card-footer-800">
        <button className="card-footer__btn-800" onClick={handleExpandClick}>Expand <FaExpandArrowsAlt /></button>
          <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Object-Detection-ML"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a>
          <a className='link-button-800' target="_blank" href="http://74.12.132.65:5000/"> <FaExternalLinkAlt className='icon-800 link-800'/></a>
        
        </div>
      </div>
    </div>
  );
  }
};


const Project9 = () => {
  const [expanded, setExpanded] = useState(false);
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);


  const handleReadMoreClick = () => {
    setReadMoreClicked(!readMoreClicked); // Toggle the state
  };

  const handleExpandClick = () => {
    
    setExpanded(!expanded);
    setTimeout(() => {
    const project3Section = document.getElementById('project9');
    if (project3Section) {
      project3Section.scrollIntoView({ behavior: 'smooth' });
    }
  }, 80); // 1500 milliseconds = 1.5 seconds
};
  
useEffect(() => {
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 848);
  };

  handleResize();

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

if (expanded) {
  return (
    
    <div id="project9" className={`card-800 body-800 expanded ${readMoreClicked ? 'read-more-clicked' : ''}`}>
     
     {isSmallScreen ? (
    <div className="card-content-8001 ">
    <div>  
    <div className="card-content-container last-project-8001">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">Englishify <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Englishify-public"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="https://englishify.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-14354C?logo=python&logoColor=white"/>
          <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?&logo=flask&logoColor=white"/>
          <img alt="React.js" className='logo-800' src="https://img.shields.io/badge/React.js-61DAFB?&logo=react&logoColor=white"/>
          <img alt="PostgreSQL" className='logo-800' src="https://img.shields.io/badge/PostgreSQL-336791?&logo=postgresql&logoColor=white"/>
          <button className="card-footer__btn-8001" onClick={handleExpandClick}><BiCollapse  /></button>
  
    </div>
    <div className='details-8001'> 
    <ReadMeEnglishify/>
      </div>
      
      </div>

      <div className="video-container">
        <div className="card-footer-8001">
        <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
        </div>
        
     
          
      </div>
      
      
    </div>
    <Englishify />
    
    </div>
    </div>
   
  ) : (
    <div className="card-content-8001">
    <div>  
    <div className="card-content-container">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">Englishify <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Englishify-public"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="https://englishify.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-14354C?logo=python&logoColor=white"/>
          <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?&logo=flask&logoColor=white"/>
          <img alt="React.js" className='logo-800' src="https://img.shields.io/badge/React.js-61DAFB?&logo=react&logoColor=white"/>
          <img alt="PostgreSQL" className='logo-800' src="https://img.shields.io/badge/PostgreSQL-336791?&logo=postgresql&logoColor=white"/>


    </div>
    <div className='details-8002'> 
    <ReadMeEnglishify/>
    </div>
      
      </div>

      
        <div className="card-footer-8001">
          <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
      </div>
      
      
    </div>
    <h4 className="card-body_2_header-8001">Try it yourself</h4>
          <Englishify />
    </div>
    </div>
  )}
  </div>
  
);
  } else {
  return (
    <div id="project9" className={`card-800 body-800 ${expanded ? 'expanded' : ''} ${readMoreClicked ? 'read-more-clicked' : ''}`}>
      <div className="card-content-800">
      <div className="card-header-800">
        <h4 className="card-body__header-800">Englishify </h4>
        <p className="card-body__lang-800">
        <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-14354C?logo=python&logoColor=white"/>
          <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?&logo=flask&logoColor=white"/>
          <img alt="React.js" className='logo-800' src="https://img.shields.io/badge/React.js-61DAFB?&logo=react&logoColor=white"/>
          <img alt="PostgreSQL" className='logo-800' src="https://img.shields.io/badge/PostgreSQL-336791?&logo=postgresql&logoColor=white"/>
        </p>
          <img src={englishify} alt="" className="card-header__image-800" />
        </div>
        <div className="card-body-800">
          <p className="card-body__desc-800">Built an educational web application that helps second-language learners improve their English by offering real-world examples from YouTube videos. Users can search for words or phrases and listen to its native pronunciations.</p>
        </div>
        <div className="card-footer-800">
        <button className="card-footer__btn-800" onClick={handleExpandClick}>Expand <FaExpandArrowsAlt /></button>
          <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Englishify-public"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a>
          <a className='link-button-800' target="_blank" href="https://englishify.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a>
        
        </div>
      </div>
    </div>
  );
  }
};



const Project10 = () => {
  const [expanded, setExpanded] = useState(false);
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);


  const handleReadMoreClick = () => {
    setReadMoreClicked(!readMoreClicked); // Toggle the state
  };

  const handleExpandClick = () => {
    
    setExpanded(!expanded);
    setTimeout(() => {
    const project3Section = document.getElementById('project10');
    if (project3Section) {
      project3Section.scrollIntoView({ behavior: 'smooth' });
    }
  }, 80); // 1500 milliseconds = 1.5 seconds
};
  
useEffect(() => {
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 848);
  };

  handleResize();

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

if (expanded) {
  return (
    
    <div id="project10" className={`card-800 body-800 expanded ${readMoreClicked ? 'read-more-clicked' : ''}`}>
     
     {isSmallScreen ? (
    <div className="card-content-8001 ">
    <div>  
    <div className="card-content-container last-project-8001">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">Toronto House Price Predictor <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Toronto-House-Prices-Predection-App"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="https://toronto-house-prices-predection-app-95qo.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-3776AB?&logo=python&logoColor=white"/>
        <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?&logo=flask&logoColor=white"/>
        <img alt="Pandas" className='logo-800' src="https://img.shields.io/badge/Pandas-150458?&logo=pandas&logoColor=white"/>
        <img alt="Scikit-learn" className='logo-800' src="https://img.shields.io/badge/Scikit--learn-F7931E?&logo=scikit-learn&logoColor=white"/>
        <img alt="React" className='logo-800' src="https://img.shields.io/badge/React-61DAFB?&logo=react&logoColor=white"/>
          <button className="card-footer__btn-8001" onClick={handleExpandClick}><BiCollapse  /></button>
  
    </div>
    <div className='details-8001'> 
    <ReadMePrediction/>
      </div>
      
      </div>

      <div className="video-container">
        <div className="card-footer-8001">
        <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
        </div>
        
     
          
      </div>
      
      
    </div>
    <Prediction />
    
    </div>
    </div>
   
  ) : (
    <div className="card-content-8001">
    <div>  
    <div className="card-content-container">
       
      <div className="card-body-8001">
      <div className="card-header-8001">
      <h4 className="card-body__header-8001">Toronto House Price Predictor <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Toronto-House-Prices-Predection-App"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a> 
      <a className='link-button-800' target="_blank" href="https://toronto-house-prices-predection-app-95qo.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a></h4>
      <span className='Languages_used-8001'>Languages:</span>
      <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-3776AB?&logo=python&logoColor=white"/>
        <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?&logo=flask&logoColor=white"/>
        <img alt="Pandas" className='logo-800' src="https://img.shields.io/badge/Pandas-150458?&logo=pandas&logoColor=white"/>
        <img alt="Scikit-learn" className='logo-800' src="https://img.shields.io/badge/Scikit--learn-F7931E?&logo=scikit-learn&logoColor=white"/>
        <img alt="React" className='logo-800' src="https://img.shields.io/badge/React-61DAFB?&logo=react&logoColor=white"/>


    </div>
    <div className='details-8002'> 
    <ReadMePrediction/>
    </div>
      
      </div>

      
        <div className="card-footer-8001">
          <button className="card-footer__btn-8001" onClick={handleExpandClick}>Collapse <BiCollapse  /></button>
      </div>
      
      
    </div>
    <h4 className="card-body_2_header-8001">Try it yourself</h4>
          <Prediction />
    </div>
    </div>
  )}
  </div>
  
);
  } else {
  return (
    <div id="project10" className={`card-800 body-800 ${expanded ? 'expanded' : ''} ${readMoreClicked ? 'read-more-clicked' : ''}`}>
      <div className="card-content-800">
      <div className="card-header-800">
        <h4 className="card-body__header-800">Toronto House Price Predictor </h4>
        <p className="card-body__lang-800">
        <img alt="Python" className='logo-800' src="https://img.shields.io/badge/Python-3776AB?&logo=python&logoColor=white"/>
        <img alt="Flask" className='logo-800' src="https://img.shields.io/badge/Flask-000000?&logo=flask&logoColor=white"/>
        <img alt="Pandas" className='logo-800' src="https://img.shields.io/badge/Pandas-150458?&logo=pandas&logoColor=white"/>
        <img alt="Scikit-learn" className='logo-800' src="https://img.shields.io/badge/Scikit--learn-F7931E?&logo=scikit-learn&logoColor=white"/>
        <img alt="React" className='logo-800' src="https://img.shields.io/badge/React-61DAFB?&logo=react&logoColor=white"/>

        </p>
          <img src={prediction} alt="" className="card-header__image-800" />
        </div>
        <div className="card-body-800">
          <p className="card-body__desc-800">Built a predictive web app using a Random Forest model to estimate Toronto house prices, analyzing trends across neighborhoods and building types to offer insights into real estate values, enabling data-driven decision-making for users.</p>
        </div>
        <div className="card-footer-800">
        <button className="card-footer__btn-800" onClick={handleExpandClick}>Expand <FaExpandArrowsAlt /></button>
          <a className='github-button-800' target="_blank" href="https://github.com/AndrewidRizk/Toronto-House-Prices-Predection-App"><BiCodeAlt className='icon-800'/> <AiFillGithub className='icon-800 github-800'/></a>
          <a className='link-button-800' target="_blank" href="https://toronto-house-prices-predection-app-95qo.vercel.app/"> <FaExternalLinkAlt className='icon-800 link-800'/></a>
        
        </div>
      </div>
    </div>
  );
  }
};



const Cards = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="cards-container" style={{position:"absolute"}}>
      <Computer/>
      <div className="cards-800">

      <Project1/>
        <Project8/>
        <Project9/>
        <Project10/>
        <Project2/>
        <Project4/>
        <Project3/>
        <Project6/>
        <Project7/>
        {windowWidth < 1000 ?
        <div style={{color:"transparent",  marginTop:"200px"}}><p>a</p>  </div>
        :
        <div style={{color:"transparent"}}><p>a</p>  </div>
        }

      </div>
    </div>
  );
};

export default Cards;