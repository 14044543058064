import React from 'react';
import './tools.css'; // Import the CSS file for styles
const ToolsContainer6001 = () => {
  return (
<div className="tools-container-6001">
    <div className="inner-container">
        <h3>Programming Languages:</h3>
        <div className="badge-container">
            <img alt="Python" className='logo-6001' src="https://img.shields.io/badge/Python-3776AB?logo=python&logoColor=white"/>
            <img alt="Java" className='logo-6001' src="https://img.shields.io/badge/Java-007396?logo=java&logoColor=white"/>
            <img alt="JSP" className='logo-6001' src="https://img.shields.io/badge/JSP-007396?logo=java&logoColor=white"/>
            <img alt="Jython" className='logo-6001' src="https://img.shields.io/badge/Jython-3776AB?logo=python&logoColor=white"/>
            <img alt="HTML" className='logo-6001' src="https://img.shields.io/badge/HTML5-E34F26?logo=html5&logoColor=white"/>
            <img alt="CSS" className='logo-6001' src="https://img.shields.io/badge/CSS3-1572B6?logo=css3&logoColor=white"/>
            <img alt="JavaScript" className='logo-6001' src="https://img.shields.io/badge/JavaScript-F7DF1E?logo=javascript&logoColor=black"/>
            <img alt="TypeScript" className='logo-6001' src="https://img.shields.io/badge/TypeScript-3178C6?logo=typescript&logoColor=white"/>
            <img alt="PHP" className='logo-6001' src="https://img.shields.io/badge/PHP-777BB4?logo=php&logoColor=white"/>
            <img alt="C" className='logo-6001' src="https://img.shields.io/badge/ -A8B9CC?logo=c&logoColor=white"/>
            <img alt="Verilog" className='logo-6001' src="https://img.shields.io/badge/Verilog-007396?logo=verilog&logoColor=white"/>
            <img alt="LaTeX" className='logo-6001' src="https://img.shields.io/badge/LaTeX-008080?logo=latex&logoColor=white"/>
            <img alt="RISC-V" className='logo-6001' src="https://img.shields.io/badge/RISC--V-EF2D5E?logo=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABhElEQVQ4jY2Sv0sCYRTHf+feOzu7d5LDs5DQxl7BYFBBbOxUcBPsxoJ/gFbextYmYmEdtLAQRQsbP2VhYWFrF0H8AW1sbGT3do7O3Hnv5tzzPq/H4wGz2YyrqytSSlH3fR+lFKVSqVjTNL3ZbPZDkiSh0+kYDAYYDAYYDAYYDIaI3W6Hx+OBx+PB9XoFoijBarViNBphu92C1+st9w6HJNztdnK5HLquK0opvV7vKWMymSAbjUZWq4XJZMIwDGKxGCKRCJ/PB9VqFV9//3OCIMDX63VEIhGEy+UCy7IgCImk+kX0vzudh7P53l+/0M+nwecTicajQbxeBwajQbhcBiPxwOpVCoWi1GtViWfz2P3fjgcxuPxcDgchmHIZrMol8s4HA54PB7MZjMajYbNZkOlUkGv1yOfz5PNZmFZlqZUKsHn85HP5wF+v1+Jx+P5fbFYxGazQavVYrFYUC6Xsbk8nU5KpRLxeBw+nw+5XE7NZBOLxWKfI1Srz9Yt7xh7rN7x+DybAAAAAElFTkSuQmCC&logoColor=white"/>
        </div>
    </div>

    <div className="inner-container">
        <div className="half-container">
            <h4>Databases:</h4>
                <div className="badge-container">
                <img alt="MySQL" className='logo-6001' src="https://img.shields.io/badge/MySQL-4479A1?logo=mysql&logoColor=white"/>
                <img alt="SQLite" className='logo-6001' src="https://img.shields.io/badge/SQLite-003B57?logo=sqlite&logoColor=white"/>
                <img alt="MongoDB" className='logo-6001' src="https://img.shields.io/badge/MongoDB-47A248?logo=mongodb&logoColor=white"/>
                <img alt="Oracle" className='logo-6001' src="https://img.shields.io/badge/Oracle-F80000?logo=oracle&logoColor=white"/>
                <img alt="Redis" className='logo-6001' src="https://img.shields.io/badge/Redis-DC382D?logo=redis&logoColor=white"/>
                <img alt="PostgreSQL" className='logo-6001' src="https://img.shields.io/badge/PostgreSQL-336791?logo=postgresql&logoColor=white"/>
                <img alt="Elastic search" className='logo-6001' src="https://img.shields.io/badge/Elasticsearch-336791?logo=Elasticsearch&logoColor=white"/>
            </div>
        </div>
        <div className="half-container">
            <h4>Frameworks:</h4>
            <div className="badge-container">
                <img alt="Flask" className='logo-6001' src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white"/>
                <img alt="Node.js" className='logo-6001' src="https://img.shields.io/badge/Node.js-339933?logo=node.js&logoColor=white"/>
                <img alt="React.js" className='logo-6001' src="https://img.shields.io/badge/React.js-20232A?logo=react&logoColor=61DAFB"/>
                <img alt="Next.js" className='logo-6001' src="https://img.shields.io/badge/Next.js-black?logo=next.js&logoColor=white"/>
            </div>
        </div>
    </div>

    <div className="inner-container">
        <h3>Software, Tools, and packages:</h3>
        <div className="badge-container">
            <img alt="Docker" className='logo-6001' src="https://img.shields.io/badge/Docker-2496ED?logo=docker&logoColor=white"/>
            <img alt="Vercel" className='logo-6001' src="https://img.shields.io/badge/Vercel-000000?logo=vercel&logoColor=white"/>
            <img alt="Hostinger" className='logo-6001' src="https://img.shields.io/badge/Hostinger-FB542B?logo=hostinger&logoColor=white"/>
            <img alt="Render" className='logo-6001' src="https://img.shields.io/badge/Render-46E3B7?logo=render&logoColor=white"/>
            <img alt="Figma" className='logo-6001' src="https://img.shields.io/badge/Figma-F24E1E?logo=figma&logoColor=white"/>
            <img alt="Git" className='logo-6001' src="https://img.shields.io/badge/Git-F05032?logo=git&logoColor=white"/>
            <img alt="GitHub" className='logo-6001' src="https://img.shields.io/badge/GitHub-181717?logo=github&logoColor=white"/>

            <img alt="Visual Studio Code" className='logo-6001' src="https://img.shields.io/badge/VSC-007ACC?logo=visual-studio-code&logoColor=white"/>
            <img alt="PyCharm" className='logo-6001' src="https://img.shields.io/badge/PyCharm-000000?logo=pycharm&logoColor=white"/>
            <img alt="IDE" className='logo-6001' src="https://img.shields.io/badge/IDE-007ACC?logo=intellij-idea&logoColor=white"/>
            
            <img alt="npm" className='logo-6001' src="https://img.shields.io/badge/npm-CB3837?logo=npm&logoColor=white"/>
            <img alt="pip" className='logo-6001' src="https://img.shields.io/badge/pip-3775A9?logo=pypi&logoColor=white"/>
            <img alt="Selenium" className='logo-6001' src="https://img.shields.io/badge/Selenium-43B02A?logo=selenium&logoColor=white"/>
            <img alt="JUnit Test" className='logo-6001' src="https://img.shields.io/badge/JUnit-25A162?logo=junit5&logoColor=white"/>
            <img alt="SQL Workshop" className='logo-6001' src="https://img.shields.io/badge/SQL%20Workshop-4479A1?logo=oracle&logoColor=white"/>
            <img alt="Maximo" className='logo-6001' src="https://img.shields.io/badge/Maximo-005DAA?logo=ibm&logoColor=white"/>

            <img alt="Chrome Development" className='logo-6001' src="https://img.shields.io/badge/Chrome%20Dev -4285F4?logo=google-chrome&logoColor=white"/>

            <img alt="Jira" className='logo-6001' src="https://img.shields.io/badge/Jira-0052CC?logo=jira-software&logoColor=white"/>
            <img alt="Confluence" className='logo-6001' src="https://img.shields.io/badge/Confluence-172B4D?logo=confluence&logoColor=white"/>
            <img alt="Google APIs" className='logo-6001' src="https://img.shields.io/badge/Google%20APIs-4285F4?logo=google&logoColor=white"/>
            <img alt="PowerApps" className='logo-6001' src="https://img.shields.io/badge/PowerApps-742774?logo=microsoft&logoColor=white"/>
        </div>
    </div>
</div>
  );
};

export default ToolsContainer6001;
