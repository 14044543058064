import React, { useRef, useMemo } from "react";
import {useState} from 'react';
import { useEffect  } from 'react';
import "./Styles/About.css";
import { BsFillBookFill } from "react-icons/bs";
import { TfiHummer } from "react-icons/tfi";
import { FaArrowUp } from "react-icons/fa6";
import { PiCertificateDuotone } from "react-icons/pi";
import App from './components/Deck/deck.tsx';
import MyTimeline from './components/Time_Line/line';
import Quotes from './components/quotes/quotes.js'
import Education2 from './components/Education/edu.tsx'
import './components/Education/index.css'
import BlurryTextBox  from './components/textBoxEducation/BlurryTextBoc.js'
import { FaComputer } from "react-icons/fa6";
import Finallskills from "./components/falling items/skills.jsx"
import Shooting from "../../Shooting.js";
import AstronautFloatAnimation  from './components/animationpics/rocket.jsx' 
import { useLocation } from 'react-router-dom'; // Assuming you are using react-router-dom for routing

export const Skills = () => {
  const Up = useRef(null);
  const Certificates = useRef(null);
  const Education = useRef(null);
  const Experiance = useRef(null);
  const skills = useRef(null);
  const scrollToSection = (elementRef) => {
    if (elementRef && elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const date = new Date();
  const options = { month: 'short', year: 'numeric' };
  const currentMonthYear = new Intl.DateTimeFormat('en-US', options).format(date);

  
  const [isHoveringStu, setIsHoveringStu] = useState(false);
  const [isHoveringEdu, setIsHoveringEdu] = useState(false);
  const [isHoveringIntro, setIsHoveringIntro] = useState(false);
  const [isHoveringExp, setIsHoveringExp] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isFadingStu, setIsFadingStu] = useState(false);
  const [isFadingEdu, setIsFadingEdu] = useState(false);
  const [isFadingIntro, setIsFadingIntro] = useState(false);
  const [isFadingExp, setIsFadingExp] = useState(false);

  const stuTimeout = useRef(null);
  const eduTimeout = useRef(null);
  const introTimeout = useRef(null);
  const expTimeout = useRef(null);

  const isMobile = windowWidth <= 768;

  const handleStuMouseOver = () => {
    setIsHoveringStu(true);
    setIsFadingStu(false);
    if (windowWidth <= 768) {
      introTimeout.current = setTimeout(() => {
        setIsFadingStu(true);
        setTimeout(() => {
          setIsHoveringStu(false);
        }, 500); // Duration of fade-out animation
      }, 1000);
    }
  };

  const handleStuMouseOut = () => {
    if (introTimeout.current) {
      clearTimeout(introTimeout.current);
    }
    setIsHoveringStu(false);
    setIsFadingStu(false);  
  };


  const handleEduMouseOver = () => {
    setIsHoveringEdu(true);
    setIsFadingEdu(false);
    if (windowWidth <= 768) {
      introTimeout.current = setTimeout(() => {
        setIsFadingEdu(true);
        setTimeout(() => {
          setIsHoveringEdu(false);
        }, 500); // Duration of fade-out animation
      }, 1000);
    }
  };

  const handleEduMouseOut = () => {
    if (introTimeout.current) {
      clearTimeout(introTimeout.current);
    }
    setIsHoveringEdu(false);
    setIsFadingEdu(false); 
  };

  const handleIntroMouseOver = () => {
    setIsHoveringIntro(true);
    setIsFadingIntro(false);
    if (windowWidth <= 768) {
      introTimeout.current = setTimeout(() => {
        setIsFadingIntro(true);
        setTimeout(() => {
          setIsHoveringIntro(false);
        }, 500); // Duration of fade-out animation
      }, 1000);
    }
  };

  const handleIntroMouseOut = () => {
    if (introTimeout.current) {
      clearTimeout(introTimeout.current);
    }
    setIsHoveringIntro(false);
    setIsFadingIntro(false);  
  };

  const handleExpMouseOver = () => {
    setIsHoveringExp(true);
    setIsFadingExp(false);
    if (windowWidth <= 768) {
      introTimeout.current = setTimeout(() => {
        setIsFadingExp(true);
        setTimeout(() => {
          setIsHoveringExp(false);
        }, 500); // Duration of fade-out animation
      }, 1000);
    }
  };

  const handleExpMouseOut = () => {
    if (introTimeout.current) {
      clearTimeout(introTimeout.current);
    }
    setIsHoveringExp(false);
    setIsFadingExp(false);  
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const location = useLocation();


  useEffect(() => {
    if (location.hash === '##education' && Education.current) {
      Education.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (location.hash === '##experience' && Experiance.current) {
      Experiance.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (location.hash === '##certificates' && Certificates.current) {
      Certificates.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className="About">
      <div className="Container">
        <ul>

        
         
          <li
            onClick={() => scrollToSection(skills)} className="link nav-item icon"
            onMouseOver={handleStuMouseOver}
            onMouseOut={handleStuMouseOut}
          >
            <div>
            <FaComputer />
             
              {isMobile ? (
              isHoveringStu &&<span className={`tooltip2 ${isFadingStu ? 'hidden' : ''}`}>
                {"                               "}Skills
              </span>
            ) : (
              isHoveringStu &&<span className="tooltip">
                {"                               "}Skills
              </span>
            )}
            </div>
            

          </li>
          <li></li>
          <li></li>
         
          <li
            onClick={() => scrollToSection(Education)} className="link nav-item icon"
            onMouseOver={handleEduMouseOver}
            onMouseOut={handleEduMouseOut}
          >
            <div>
            <BsFillBookFill />
              {isMobile ? (
              isHoveringEdu &&<span className={`tooltip2 ${isFadingEdu ? 'hidden' : ''}`}>
                {"                               "}Education
              </span>
            ) : (
              isHoveringEdu &&<span className="tooltip">
                {"                               "}Education
              </span>
            )}
            </div>
            

          </li>

          
           
          <li></li>
          <li></li>
          
          <li onClick={() => scrollToSection(Certificates)} className="link nav-item icon"
            onMouseOver={handleIntroMouseOver}
            onMouseOut={handleIntroMouseOut}
          >
            
            <div>
              <PiCertificateDuotone />
              {isMobile ? (
              isHoveringIntro &&<span className={`tooltip2 ${isFadingIntro ? 'hidden' : ''}`}>
                {"                               "}Certificates
              </span>
            ) : (
              isHoveringIntro &&<span className="tooltip">
                {"                               "}Certificates
              </span>
            )}
            </div>

          </li>

          
          <li></li>
          <li></li>
          

          <li
            onClick={() => scrollToSection(Experiance)} className="link nav-item icon"
            onMouseOver={handleExpMouseOver}
            onMouseOut={handleExpMouseOut}
          >
            <div >
              <TfiHummer />
              {isMobile ? (
              isHoveringExp &&<span className={`tooltip2 ${isFadingExp ? 'hidden' : ''}`}>
                {"                               "}Time Line
              </span>
            ) : (
              isHoveringExp &&<span className="tooltip">
                {"                               "}Time Line
              </span>
            )}
            </div>

          </li>
        </ul>
      </div>
      

      <div ref={skills} className="skills" style={{ position: 'relative' }}>
        <Finallskills/>
      </div>
      
      <div    className="Education" style={{ position: 'relative'}}>
        <Education2/>
        <div ref={Education}><BlurryTextBox /></div>
        <AstronautFloatAnimation/>
      </div>
      
      <div ref={Certificates} className="Certificates" style={{ position: 'relative'}}>
       
        <App />
      </div>

      <div ref={Experiance} className="Experiance">
        <h3 className="time-line-17">{currentMonthYear}</h3>
        <MyTimeline />
      </div>

              {windowWidth < 1000 ?
        <div style={{color:"transparent",  marginTop:"200px"}}><p>a</p>  </div>
        :
        <div style={{color:"transparent"}}><p>a</p>  </div>
        }

    </div>
  );
};



/* Random Pics */


