import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../Styles/NavBar.css";
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import { HiOutlineHome } from "react-icons/hi";
import Logo1 from "./Logos/Logo2.png.png";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { IoNewspaperOutline } from "react-icons/io5";
import Resume from '../components/Pages/components/Resume/Andro_Rizk_Fall_2024.pdf';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function NavBar() {
  const [toggle, setToggle] = useState(false);
  const [click, setClick] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleClick = () => setClick(!click);
  

  // Update the windowWidth when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const pages = ['/', '/about', '/skills', '/projects', '/contact'];
  const names = ['Home', 'About Me', 'Qualifications', 'Projects', 'Contact Me'];

  useEffect(() => {
    // Determine the current page index based on the current URL
    const currentPagePath = window.location.pathname;
    const pageIndex = pages.indexOf(currentPagePath);
    setCurrentPageIndex(pageIndex !== -1 ? pageIndex : 0);
  }, []);

  const handlePreviousClick = () => {
    const newIndex = (currentPageIndex - 1 + pages.length) % pages.length;
    setCurrentPageIndex(newIndex);
  };

  const handleMenuClick = () => {
    setNavMenuOpen(!navMenuOpen);
  };

  const handleNextClick = () => {
    const newIndex = (currentPageIndex + 1) % pages.length;
    setCurrentPageIndex(newIndex);
  };
  
  // Handle click on navigation links
  const handleNavItemClick = (index) => {
    setCurrentPageIndex(index);
    setNavMenuOpen(false); // Close the menu if open
  };
  // Determine the current page name
  const currentPage = names[currentPageIndex];

  return (
    <nav className={toggle ? 'nav-container_expanded ' : 'nav-container_expanded2 nav-menu '}>
      <div className="  wrapper header">
        {windowWidth <= 769 && (
         <nav className="navbar" style={{ width: '100%', marginTop:"5px", background:"black", zIndex:"1000000"}}>
         <div style={{ color: "white", fontSize: "24px", position: "absolute", left: "10px", zIndex: "10", top: "0" , marginTop:"6px"}} onClick={handlePreviousClick}>
           <NavLink to={pages[(currentPageIndex - 1 + pages.length) % pages.length]} style={{ color: "white", textDecoration: "none" }}>
             <FaChevronLeft />
           </NavLink>
         </div>
         <div className="nav-page-square" onClick={handleMenuClick} style={{ color:"white" ,border: "2px solid white", borderRadius: "10px", padding: "10px", fontSize:"14px",  paddingTop:"5px",paddingBottom:"5px", cursor: "pointer", maxWidth: "150px", margin: "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
           {currentPage}
         </div>
         <div style={{ color: "white", fontSize: "24px", position: "absolute", right: "10px", zIndex: "10", top: "0" , marginTop:"6px"}} onClick={handleNextClick}>
           <NavLink to={pages[(currentPageIndex + 1) % pages.length]} style={{ color: "white", textDecoration: "none" }}>
             <FaChevronRight />
           </NavLink>
         </div>
         <div className={`nav-menu ${navMenuOpen ? 'open' : ''}`} style={{ position: "absolute", top: "40px", left: "50%", transform: "translateX(-50%)", backgroundColor: "black", width:"150px", borderRadius: "10px", border: "2px solid white", padding: "10px", visibility: navMenuOpen ? "visible" : "hidden", opacity: navMenuOpen ? 1 : 0, transition: "all 0.3s ease", zIndex: "1000" }}>
           <ul className="nav-links">
             <li className="nav-item">
               <NavLink exact to="/about" activeClassName="active" onClick={() => handleNavItemClick(1)} style={{ color: "white", textDecoration: "none", margin:"auto"}}>
                 About Me
               </NavLink>
             </li>
             <li className="nav-item">
               <NavLink exact to="/skills" activeClassName="active" onClick={() => handleNavItemClick(2)} style={{ color: "white", textDecoration: "none" }}>
                 Qualifications
               </NavLink>
             </li>
             <li className="nav-item">
               <NavLink exact to="/projects" activeClassName="active" onClick={() => handleNavItemClick(3)} style={{ color: "white", textDecoration: "none" }}>
                 Projects
               </NavLink>
             </li>
             <li className="nav-item">
               <NavLink exact to="/contact" activeClassName="active" onClick={() => handleNavItemClick(4)} style={{ color: "white", textDecoration: "none" }}>
                 Contact Me
               </NavLink>
             </li>
           </ul>
         </div>
       </nav>
        )}
        <div className="nav-container">
        {windowWidth > 769 && ( // Render links only on larger screens
        
          <ul className='links dropdown'>

          <div className="social-links-1001">
                <a href="https://github.com/AndrewidRizk" target="_blank" rel="noopener noreferrer">
                  <FaGithub />
                </a>
                <a href="https://www.linkedin.com/in/andrewrizk3030/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin />
                </a>
            </div>
            <div className="social-links-1002">
              
              <a href={Resume} download={Resume} target="_blank" rel="noopener noreferrer">
                  <IoNewspaperOutline />
                </a>
            </div>

            <li className="nav-item dropbtn linkItem">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links aboutme1 "
                onClick={handleClick}
              >
                About Me
              </NavLink>
            </li>

            <li className="nav-item linkItem">
              <NavLink
                exact
                to="/skills"
                activeClassName="active"
                className="nav-links "
                onClick={handleClick}
              >
                Qualification
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="LOGO"
                className="nav-links2"
                onClick={handleClick}
              >
                <img src={Logo1} alt="Logo" style={{ width: 40 }} />
              </NavLink>
            </li>

            
            <li className="nav-item linkItem">
              <NavLink
                exact
                to="/projects"
                activeClassName="active"
                className="nav-links "
                onClick={handleClick}
              >
                Projects
              </NavLink>
            </li>
            <li className="nav-item linkItem">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links "
                onClick={handleClick}
              >
                Contact Me
              </NavLink>
            </li>
          </ul>
        )}
      </div>
      </div>
    </nav>
  );
}

export default NavBar;
