import React from 'react';
import './quotes.css';

function Quotes() {
  return (
    <div className='body3'>
      <h1>
        <span>"Success </span>
        <span>is   </span>
        <span>no </span>
        <span>not </span>
        <span>final, </span>
        <span>failure  </span>
        <span>is  </span>
        <span>not  </span>
        <span>fatal: </span>
        <span>it  </span>
        <span>is </span>
        <span>the </span>
        <span>courage</span>
        <span> to    </span>
        <span>continue </span>
        <span>that</span>
        <span> counts."</span>
        <span>- Winston Churchill</span>
      </h1>
    </div>
  );
}

export default Quotes;
