import React from 'react';
import './CenteredButton.css'; // Importing CSS for styles

const CenteredButton = () => {
    return (
        // Corrected 'herf' to 'href' and updated the class name if necessary
        <div>
        <a href="./about" className="centered-button-2003">About Me</a>
        <a href="./skills" className="centered-button-2001">Qualifications </a>
        <a href="./projects" className="centered-button-2002">Projects</a>
        <a href="./contact" className="centered-button-2006">Contact Me</a>
        </div>
    );
};

export default CenteredButton;
