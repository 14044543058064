import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import './ReadmeContainer.css'; // Import CSS for styling

const ReadmeContainer = () => {
  const [readmeContent, setReadmeContent] = useState('');

  useEffect(() => {
    const fetchReadme = async () => {
      try {
        const response = await fetch(
          'https://raw.githubusercontent.com/AndrewidRizk/Reviewify-Business/main/README.md'
        );
        if (response.ok) {
          const markdown = await response.text();
          setReadmeContent(markdown);
        } else {
          throw new Error('Failed to fetch README');
        }
      } catch (error) {
        console.error('Error fetching README:', error);
      }
    };

    fetchReadme();
  }, []);

  const components = {
    ul: ({ children }) => <ul className="nested">{children}</ul>,
    ol: ({ children }) => <ol className="nested">{children}</ol>,
    li: ({ children }) => <li className="nested">{children}</li>,
    p: ({ children }) => <>{children}</>, // Preserve paragraph spacing
  };

  return (
    <div className='readme-container-8002'>
      <ReactMarkdown components={components}>{readmeContent}</ReactMarkdown>
    </div>
  );
};

export default ReadmeContainer;
