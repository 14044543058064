import React from 'react';
import './styles.css'; // Import your stylesheet

const BlurryTextBox = () => {
  return (
    <div className="textBoxContainer15">
      <h2 className="textBoxHeader15">🎓 Academic Journey</h2>
      <div className="textBoxContent15">
        <div className="educationItem15">
          <h3 className="degree15">Bachelor of Science in Computer Science</h3>
          <p className="university15">York University, Toronto</p>
          <p className="gpa15">GPA: <strong>3.8</strong></p>
          <p className="courses15">
            🚀 Relevant Courses:
            <br />
            - Building E-Commerce Systems
            <br />
            - Advanced Object-Oriented Programming
            <br />
            - Machine Learning and Artificial intelligence
            <br />
            
          </p>
          <br />
          <br />
        </div>
      </div>
      <p className="onlineCourses15">
        🌐 Explored additional realms through various online courses. Hover over the Certificates and slide them left and right to discover more!
      </p>
    </div>
  );
};

export default BlurryTextBox;
