import React from 'react';
import './Styles/index.css';
import ReactDOM from 'react-dom/client'
import App from './App';
import styled from 'styled-components';


import { createRoot } from 'react-dom/client';
const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 48%; /* Adjust based on your layout needs */
`;
const root = createRoot(document.getElementById('root'));

root.render(<div className='body'>
      <React.StrictMode>

          <App /> 
     </React.StrictMode>

     </div>);


