import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Proj from "./components/proj/App.jsx";
import "./Styles/proj.css"
export const Projects = () => {
  

  return (
    <div className='bodyconrainer-70001'>
      
      <Proj />

    </div>

  );
};
