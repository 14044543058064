import React from 'react';
import './Styles/ShootingStar.css';

class Shooting extends React.Component {
  render() {
    return (
      <section>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </section>
    );
  }
}

export default Shooting;
