import React from 'react';
import styled, { keyframes } from 'styled-components';
import astronautWithComputer from './Connect.png'; // Adjust the path to your image

const floatVertical = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: translateY(-5px)scale(1.1); /* Adjust the pulsate scale as needed */
  }
`;

const FloatingAstronaut = styled.img`
  position: absolute; /* Stay within the parent container */
  top:8%;
  left: 2%; /* Adjust position as needed */
  width: 80px; /* Default size */
  height: auto;
  animation: ${floatVertical} 3s ease-in-out infinite;
  z-index: 9999; /* Ensure it is on top of everything */
  transform: scaleX(-1) rotateY(180deg); /* Flip the image horizontally */

  @media (max-width: 1448px) {
    width: 80px; /* Bigger size */
    left: 1%; /* Adjust position for larger screens */
  }
  @media (max-width: 1300px) {
    width: 80px; /* Bigger size */
    left: 0%; /* Adjust position for larger screens */
   
  }
  @media (max-width: 1085px) {
    width: 85px; /* Smaller size */
  }
  @media (max-width: 893px) {
    width: 80px; /* Smaller size */
  }
  @media (max-width: 769px) {
    width: 75px; /* Smaller size */
    top:1%;
  }
  @media (max-width: 688px) {
    width: 75px; /* Smaller size */
    top:-0.5%;
    left:-2%;
  }

  @media (max-width: 348px) {
    width: 70px; /* Smaller size */
  }
  @media (min-width: 1449px) {
    width: 90px; /* Bigger size */
  }
`;

const AstronautWithComputer = () => {
  return <FloatingAstronaut src={astronautWithComputer} alt="Astronaut with Computer" />;
};

export default AstronautWithComputer;
